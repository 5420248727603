import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Modal, ModalOverlay, ModalContent, Box, ModalHeader, Card, CardHeader, Heading, Flex, ModalBody, IconButton, VStack, Button, Text, HStack, Icon, Divider, Avatar, Stat, StatLabel, StatNumber, SimpleGrid, Checkbox, useBreakpointValue, Wrap, WrapItem } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { HiOutlineClipboardList } from "react-icons/hi";
import { GiUpgrade } from "react-icons/gi";
import NotCompletedSignOff from "./NotCompletedSignOff";

function CompletedCard(props) {
    let { name, iconImage, handleUnsignOffCheckedItems, unsignOffCheckedItems } = props;

    return (
        <Card align='left'>
            <Checkbox px={4} size="lg" width="fill-available" isChecked={unsignOffCheckedItems.includes(name)} onChange={() => handleUnsignOffCheckedItems(name)}>
                <CardHeader px={2}>
                    <Flex flex='1' gap='4' flexWrap="wrap">
                        <Avatar name={name.split(' ')[0]} src={iconImage} />
                        <Box>
                            <Heading size='sm' isTruncated>{name}</Heading>
                            <Text color="darkgray">Completed</Text>
                        </Box>
                    </Flex>
                </CardHeader>
            </Checkbox>
        </Card>
    )
}

export default function SignOffModal(props) {

    const { isOpen, onClose, members, getAssignments, getSkills } = props;
    const { _id, assignmentName, assigned, completed, skillName } = props.data;

    const [checkedItems, setCheckedItems] = useState([]);
    const [unsignOffCheckedItems, setUnsignOffCheckedItems] = useState([]);
    const [scrollBoxHeight, setScrollBoxHeight] = useState(0);

    const initialRef = useRef(null);
    const headerHeight = useRef(null);
    const sideBarHeaderHeight = useRef(null);
    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

    const mapAssigned = useCallback((handleCheckItem) => assigned.filter(item => !new Set(completed).has(item)).map((filteredData) => {
        let iconImage = members.length > 0 ? members.find(item => item.name === filteredData)?.iconImage : null;
        return <NotCompletedSignOff key={filteredData} name={filteredData} iconImage={iconImage} checkedItems={checkedItems} handleCheckItem={handleCheckItem} />
    }), [assigned, checkedItems]);

    const handleCheckItem = (item) => {
        let checkedItemsCopy = checkedItems;
        if (checkedItems.includes(item)) {
            checkedItemsCopy = checkedItemsCopy.filter((i) => i !== item);
            setCheckedItems(checkedItemsCopy);
        } else {
            checkedItemsCopy = [...checkedItemsCopy, item];
            setCheckedItems(checkedItemsCopy);
        }
    };

    const handleUnsignOffCheckedItems = (item) => {
        let checkedItemsCopy = unsignOffCheckedItems;
        if (unsignOffCheckedItems.includes(item)) {
            checkedItemsCopy = checkedItemsCopy.filter((i) => i !== item);
            setUnsignOffCheckedItems(checkedItemsCopy);
        } else {
            checkedItemsCopy = [...checkedItemsCopy, item];
            setUnsignOffCheckedItems(checkedItemsCopy);
        }
    };

    const handleCheckAll = (e) => {
        setCheckedItems(e.target.checked ? assigned.filter((name) => !completed.includes(name)).map(filteredName => filteredName) : []);
    };

    const onCloseHandler = () => {
        setCheckedItems([]);
        setUnsignOffCheckedItems([]);
        onClose();
    }

    const signOffAssignmentOnClickHandler = (action) => {
        let mergedSet;
        if (action === "signoff") {
            mergedSet = merge(checkedItems, completed);
        } else {
            mergedSet = completed.filter(e => !unsignOffCheckedItems.includes(e));
        }
        fetch(`/assignmentsTransactions/signOffAssignment`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: _id,
                completed: mergedSet
            })
        }).then(response => {
            if (response.status === 200) {
                action === "signoff" ? setCheckedItems([]) : setUnsignOffCheckedItems([]);
                getAssignments();
            }
            return response.text();
        }).then(function (data) {
            (data) && console.log(data);
        })
            .catch(err => {
                console.log(err);
            });
    }

    const signOffSkillOnClickHandler = (action) => {
        let mergedSet;
        if (action === "signoff") {
            mergedSet = merge(checkedItems, completed);
        } else {
            console.log(completed)
            console.log(unsignOffCheckedItems);
            mergedSet = completed.filter(e => !unsignOffCheckedItems.includes(e));
        }
        fetch(`/assignmentsTransactions/signOffSkill`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: _id,
                completed: mergedSet
            })
        }).then(response => {
            if (response.status === 200) {
                action === "signoff" ? setCheckedItems([]) : setUnsignOffCheckedItems([]);
                getSkills();
            }
            return response.text();
        }).then(function (data) {
            (data) && console.log(data);
        })
            .catch(err => {
                console.log(err);
            });
    }
    useLayoutEffect(() => {
        if (isOpen)
            setTimeout(() => setScrollBoxHeight(window.innerHeight - (sideBarHeaderHeight.current.offsetHeight + headerHeight.current.offsetHeight + 34)), 1);
    }, [isOpen]);

    return (
        <Modal
            initialFocusRef={initialRef}
            isOpen={isOpen}
            onClose={onCloseHandler}
            size="full"
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent userSelect="none">
                <ModalHeader ref={headerHeight}>
                    <HStack spacing='12px'>
                        <IconButton
                            isRound={true}
                            aria-label="Close"
                            icon={<CloseIcon />}
                            onClick={onCloseHandler}
                            position="relative"
                            backgroundColor="transparent"
                        />
                        <Avatar background={getSkills ? "cyan.100" : "red.100"} w={isMobile ? "1.75rem" : "2.5rem"} h={isMobile ? "1.75rem" : "2.5rem"}
                            icon={<Icon as={getSkills ? GiUpgrade : HiOutlineClipboardList}
                                w={isMobile ? 5 : 7} h={isMobile ? 5 : 7} color={getSkills ? "blue.500" : 'orange.500'} />} />
                        <Text fontSize={isMobile ? "md" : "3xl"}>Sign Off</Text>
                        {!isMobile && <Box flex="1" />}
                        <Text fontSize={isMobile ? "sm" : "md"} fontWeight="light" color="darkgray">{checkedItems.length} selected</Text>
                        <Button size={isMobile ? "sm" : "md"} colorScheme='blue' variant='solid' isDisabled={checkedItems.length === 0}
                            onClick={() => getSkills ? signOffSkillOnClickHandler("signoff") : signOffAssignmentOnClickHandler("signoff")}>
                            Sign Off
                        </Button>
                    </HStack>
                </ModalHeader>
                <Divider />
                <ModalBody p={0} display="flex" backgroundColor="blackAlpha.400">
                    <Flex flex="1">
                        <Box bg="gray.700" w={isMobile ? '190px' : '340px'} borderRight="1px solid rgba(255,255,255,0.16)">
                            <VStack ref={sideBarHeaderHeight} m={3} spacing={3} alignItems={'normal'}>
                                <Box>
                                    <Heading size="lg">Assigned</Heading>
                                    <Text color="darkgray">Not Completed</Text>
                                </Box>
                                <Checkbox size="lg" isChecked={checkedItems.length === (assigned.length - completed.length)} onChange={handleCheckAll}>Select all</Checkbox>
                            </VStack>
                            <Box
                                height={scrollBoxHeight}
                                overflowY="auto"
                                sx={{
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: 'gray.300',
                                        borderRadius: 'md',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: 'transparent',
                                    },
                                }}
                            >
                                {mapAssigned(handleCheckItem)}
                            </Box>
                        </Box>
                        <Box p={4} px={isMobile ? 1 : 4} overflowY={'scroll'} flex="1">
                            <Wrap>
                                <WrapItem>
                                    <Heading size="lg" m={4} my={0}>{skillName || assignmentName}</Heading>
                                </WrapItem>
                                <WrapItem>
                                    <Button size={isMobile ? "sm" : "md"} colorScheme='orange' variant='solid' isDisabled={unsignOffCheckedItems.length === 0}
                                        onClick={() => getSkills ? signOffSkillOnClickHandler("remove") : signOffAssignmentOnClickHandler("remove")}>
                                        Remove Sign Off
                                    </Button>
                                </WrapItem>
                            </Wrap>
                            <HStack m={4}>
                                <Stat flex="0">
                                    <StatNumber fontSize={isMobile ? '2xl' : '4xl'}>{completed.length}</StatNumber>
                                    <StatLabel mr={isMobile ? 2 : 4} fontSize={isMobile ? 'xs' : 'sm'}>Completed</StatLabel>
                                </Stat>
                                <Stat flex="0" borderLeft="1px solid rgba(255,255,255,0.16)">
                                    <StatNumber ml={isMobile ? 2 : 6} fontSize={isMobile ? '2xl' : '4xl'}>{assigned.length}</StatNumber>
                                    <StatLabel mx={isMobile ? 2 : 6} fontSize={isMobile ? 'xs' : 'sm'}>Assigned</StatLabel>
                                </Stat>
                            </HStack>
                            <SimpleGrid minChildWidth='350px' spacing={4}>
                                {completed.map((data) => {
                                    let iconImage = members.length > 0 ? members.find(item => item.name === data)?.iconImage : null;
                                    return <CompletedCard key={data} name={data} iconImage={iconImage}
                                        handleUnsignOffCheckedItems={handleUnsignOffCheckedItems} unsignOffCheckedItems={unsignOffCheckedItems} />
                                })}
                            </SimpleGrid>
                        </Box>
                    </Flex>

                </ModalBody>
            </ModalContent >
        </Modal >
    )
}

const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
}