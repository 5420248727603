import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Avatar, Badge, Box, Button, Card, CardBody, Container, Divider, HStack, Heading, Icon, Tab, TabList, Tabs, Text, VStack, useToast } from '@chakra-ui/react';
import { AuthContext } from 'context/auth';
import { DataContext } from 'context/data';
import { useLocation, useParams } from 'react-router-dom';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import LinkCard from 'components/LinkCard';
import { IoMdCalendar } from 'react-icons/io';
import { CopyIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import FailedLoginPage from './FailedLoginPage';
import NotFound from './NotFound';

export default function ViewOutreachEvent(props) {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    
    if (user === 'NoUser') {
        return <FailedLoginPage redirect={location.pathname}/>
    }

    const { outreachEvents, getOutreachEvents } = useContext(DataContext);

    const toast = useToast();
    const toastId = 'copy-toast'

    const { id } = useParams();

    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState({ address: null, lat: 40.7419, lng: -73.9279 });
    const [submitting, setSubmitting] = useState(true);

    const outreachEvent = useMemo(() => outreachEvents.find(event => event._id === id), [outreachEvents]);
    const checkedIn = useMemo(() => outreachEvent?.completed.includes(user.displayName), [outreachEvent, user]);

    const daysDiff = useMemo(() => dayjs().diff(outreachEvent?.outreachEventDateTime, 'day', true), [outreachEvent]);

    useEffect(() => {
        setSelectedPlace({ address: outreachEvent?.outreachEventAddress, lat: outreachEvent?.outreachEventCoords[0], lng: outreachEvent?.outreachEventCoords[1] });
    }, [outreachEvents]);

    useEffect(() => {
        if (typeof google !== 'object') {
            const result = useJsApiLoader({
                googleMapsApiKey: 'AIzaSyBb5rJ23Aw6iPpdww0pYA69W5tKRMjKSIE',
                // @ts-ignore
                libraries: libraries,
            });
            setIsLoaded(result.isLoaded);
        } else {
            setIsLoaded(true);
        }
        setTimeout(() => setSubmitting(false), 1000);
    }, []);

    function checkInOnClick() {
        setSubmitting(true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            setSubmitting(false);
            toast({
                status: 'error',
                position: 'top',
                title: 'Error while checking in',
                description: 'Geolocation not supported'
            })
        }
    }

    function isWithinRange(coord1, coord2, range) {
        const [lat1, lon1] = coord1;
        const [lat2, lon2] = coord2;

        // Calculate distance using the Haversine formula
        const R = 3963; // Earth radius in miles
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;

        return distance <= range;
    }

    function success(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        // check if location is within 1 mile of event
        const inRange = isWithinRange([latitude, longitude], [selectedPlace.lat, selectedPlace.lng], 1.0);
        if (inRange) {
            fetch(`/assignmentsTransactions/checkIntoOutreachEvent`, {
                method: 'POST',
                // We convert the React state to JSON and send it as the POST body
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id: outreachEvent._id
                })
            }).then(response => {
                if (response.status === 200) {
                    toast({
                        status: 'success',
                        position: 'top',
                        title: 'Success',
                        description: 'Checked In'
                    })
                    getOutreachEvents();
                    return response.text();
                };
                throw new Error(response.statusText);
            }).catch(err => {
                toast({
                    status: 'error',
                    position: 'top',
                    title: 'Error while checking in',
                    description: err.toString()
                })
                console.log(err);
            }).finally(() =>
                setSubmitting(false))
        } else {
            setSubmitting(false);
            toast({
                status: 'warning',
                position: 'top',
                title: 'Not in range of event'
            });
        }
    }

    function error() {
        setSubmitting(false);
        toast({
            status: 'error',
            position: 'top',
            title: 'Error while checking in',
            description: 'Unable to retrieve your location'
        });
    }

    if (outreachEvents.length > 0 && !outreachEvent) {
        return <NotFound />
    } else {
        return (
            <>
                <Box userSelect="none" background={"gray.800"} mb={4}>
                    <Tabs isFitted index={0} size="lg" colorScheme="green" background={"gray.800"} >
                        <Box background={"#3e3e3e"} overflow='auto'
                            position='sticky'
                            top='80px'
                            zIndex='998'
                            css={{
                                '&::-webkit-scrollbar': {
                                    height: '8px',
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    width: '6px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '24px',
                                },
                            }}>
                            <TabList width={"auto"} borderBottom="unset" minWidth={"fit-content"} zIndex={998} position={"sticky"}>
                                <Tab width={`${100 / 1}%`} mb={0} _hover={{ backgroundColor: '#3b473f' }} fontSize={{ base: 'sm', md: 'lg' }} backgroundColor={'#3b473f'}>Outreach Event</Tab>
                                <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: `calc(100% / 1)`,
                                    height: '3px',
                                    background: '#68D391',
                                    borderRadius: '5px',
                                    transition: 'all 350ms ease',
                                    zIndex: '998',
                                    left: `calc((calc(100% / 2) * 0))`
                                }}></div>
                            </TabList>
                        </Box>
                        <Container maxW={'3xl'} overflowX='hidden'>
                            <HStack my={4} spacing='12px'>
                                <HStack flex="1">
                                    <Avatar background="teal.100" w="2.5rem" h="2.5rem" icon={<Icon as={IoMdCalendar} w={7} h={7} color='green.600' />} />
                                    <Text fontSize={{ base: "lg", md: "3xl" }} fontWeight='bold' wordBreak={'break-all'}>{outreachEvent?.outreachEventName}</Text>
                                </HStack>
                                <Box textAlign='end'>
                                    <Button colorScheme='blue' variant='solid' w="fit-content" isDisabled={checkedIn || submitting || daysDiff > 1 || daysDiff < 0} onClick={() => checkInOnClick()}>
                                        {daysDiff > 1 ? 'Event Passed' : checkedIn ? 'Checked-In' : 'Check-In'}
                                    </Button>
                                </Box>
                            </HStack>
                            <Box flex="1" width="100%">
                                <Card maxW='3xl' border="1px solid rgba(255,255,255,0.16)">
                                    <CardBody>
                                        <VStack alignItems='flex-start'>
                                            <Heading size='sm'>Check-In Status</Heading>
                                            {outreachEvent && <Badge variant="solid" colorScheme={checkedIn ? 'green' : 'gray'}>
                                                {checkedIn ? 'Checked-In' : 'Not Checked-In'}
                                            </Badge>}
                                            <Divider />
                                            <Heading size='sm'>Date</Heading>
                                            <Text>{outreachEvent ? dayjs(outreachEvent?.outreachEventDateTime).format('MMMM D YYYY') : null}</Text>
                                            <Divider />
                                            <Heading size='sm'>Time</Heading>
                                            <Text>{outreachEvent ? dayjs(outreachEvent?.outreachEventDateTime).format('h:mm a') : null}</Text>
                                            <Divider />
                                            {outreachEvent?.outreachEventInstructions &&
                                                <><Heading size='sm'>Instructions</Heading>
                                                    <Text>{outreachEvent?.outreachEventInstructions}</Text>
                                                    <Divider />
                                                </>}
                                            <Heading size='sm'>Address</Heading>
                                            <Box onClick={() => {
                                                if (!toast.isActive(toastId)) {
                                                    toast({
                                                        id: toastId,
                                                        position: 'top',
                                                        title: 'Address copied',
                                                    })
                                                }
                                                navigator.clipboard.writeText(outreachEvent?.outreachEventAddress)
                                            }
                                            }>
                                                <Text _hover={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                    {outreachEvent?.outreachEventAddress} <CopyIcon />
                                                </Text>
                                            </Box>
                                            <Box onClick={() => window.open("https://maps.google.com?q=" + outreachEvent?.outreachEventAddress)}>
                                                <Text color='blue.200' _hover={{ textDecoration: 'underline', cursor: 'pointer' }}>
                                                    Google Maps <ExternalLinkIcon />
                                                </Text>
                                            </Box>
                                        </VStack>
                                    </CardBody>
                                </Card>
                                {(outreachEvent?.outreachEventLinks.length > 0) &&
                                    <Card mt={3} maxW='3xl' border="1px solid rgba(255,255,255,0.16)">
                                        <CardBody>
                                            <Heading size='sm'>Links</Heading>
                                            {outreachEvent?.outreachEventLinks.map(link => {
                                                return <HStack key={link} mt={2}>
                                                    <LinkCard url={link} />
                                                </HStack>
                                            })}
                                        </CardBody>
                                    </Card>
                                }
                                {(!isLoaded || !selectedPlace.address || !selectedPlace.lat || !selectedPlace.lng) ? (
                                    <h3>Loading.....</h3>
                                ) : (
                                    <Card mt={3} maxW='3xl' border="1px solid rgba(255,255,255,0.16)">
                                        <CardBody>
                                            <Box>
                                                <Heading size='xs' mb={2}>
                                                    Location
                                                </Heading>
                                                <GoogleMap
                                                    mapContainerStyle={{ height: '300px', width: '100%' }}
                                                    center={selectedPlace}
                                                    zoom={16}
                                                    options={{ disableDefaultUI: true }}
                                                >
                                                    <MarkerF position={selectedPlace} />
                                                </GoogleMap>
                                            </Box>
                                        </CardBody>
                                    </Card>
                                )}
                            </Box>
                        </Container>
                    </Tabs>
                </Box>
            </>
        );
    }
}
const MenuItemStyle = {
    _hover: { bg: "blackAlpha.200" },
    paddingTop: 3,
    paddingBottom: 3
}