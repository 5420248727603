import { Badge, Box, SimpleGrid, Popover, PopoverTrigger, Tag, Text, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, Divider, useDisclosure, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";
import React from "react";
import LinkCard from "./LinkCard";

export default function AssignmentOverviews(props) {

    const { assignment } = props;

    const { isOpen: isOpenPopover, onToggle: onTogglePopover, onClose: onClosePopover } = useDisclosure();
    return (
        <Box py={3}>
            <Flex>
                <Text isTruncated fontWeight={'bold'} w='50%'>
                    {assignment.assignmentName}
                </Text>
                <SimpleGrid columns={[1, null, 3]} w='50%'>
                    {assignment.uploads.length > 0 ?
                        <Popover isLazy
                            returnFocusOnClose={false}
                            isOpen={isOpenPopover}
                            onClose={onClosePopover}
                            closeOnBlur={false}>
                            <PopoverTrigger>
                                <Tag onClick={onTogglePopover} w={'fit-content'} alignContent={'center'} justifySelf={'flex-end'} _hover={{ cursor: 'pointer' }}>
                                    View Uploads
                                </Tag>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>Uploads</PopoverHeader>
                                <PopoverBody>
                                    {assignment.uploads.map((e, i) => {
                                        return <Box key={i} mb={assignment.uploads.length - 1 !== i ? 2 : 0}>
                                            <LinkCard url={`https://drive.google.com/file/d/${e}`} />
                                        </Box>
                                    })}
                                </PopoverBody>
                            </PopoverContent>
                        </Popover> : <Box />}
                    <Text fontSize={'xs'} alignContent={'center'} justifySelf={'flex-end'} color='darkgray'>
                        {assignment.dueDate ? dayjs(assignment.dueDate).format('MMM D YYYY') : 'No due date'}
                    </Text>
                    <Flex alignContent={'center'} justifySelf={'flex-end'} alignItems={'center'}>
                        {assignment.completed ?
                            <Badge variant='solid' colorScheme={'green'} h="fit-content">
                                Completed
                            </Badge>
                            :
                            <Badge variant='solid' colorScheme={assignment.pastDue ? 'red' : 'orange'} h="fit-content">
                                {assignment.pastDue ? 'Past Due' : 'Missing'}
                            </Badge>
                        }</Flex>
                </SimpleGrid>
            </Flex>
        </Box>
    )
}
