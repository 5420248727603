import {
    Avatar,
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    Center,
    Flex,
    Image,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Stack,
    Tooltip,
    useBreakpointValue,
    useColorModeValue
} from '@chakra-ui/react';
import { AuthContext } from 'context/auth';
import { DataContext } from 'context/data';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { config } from 'util/constants';
import logo from '../images/RTLogoNoClaws.webp';
import { CheckCircleIcon, WarningTwoIcon } from '@chakra-ui/icons';

export default function Nav() {
    const { user } = useContext(AuthContext);
    const { compliance, assignmentsCount } = (user === null || user === 'NoUser') ? [] : useContext(DataContext);

    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

    const [missingCompliance, setMissingCompliance] = useState(false);
    const [academicProbation, setAcademicProbation] = useState(false);

    useEffect(() => {
        if (compliance?.length > 0 && compliance.find(e => e.type === 'Academic Probation').names.includes(user.displayName)) {
            setMissingCompliance(true);
            setAcademicProbation(true);
        }
        if (assignmentsCount?.PastDue > 0) {
            setMissingCompliance(true);
        }
    }, [assignmentsCount, compliance]);

    return (
        <>
            <Box position={'sticky'} zIndex={999} top={0} w={'100%'} className='navbar' backgroundColor={useColorModeValue('#3e3e3e', '#202020')} px={4} userSelect={'none'}>
                {/* <Flex h={20} alignItems={'center'} justifyContent={'space-between'}> */}
                <Stack h={20} direction={'row'}>
                    <Box h='75px' as={Link} to={'/'}>
                        <Image
                            w={isMobile ? '100px' : '120px'}
                            minW={isMobile ? '100px' : '120px'}
                            src={logo}
                            _hover={{
                                cursor: 'pointer',
                            }}
                            position={'relative'}
                            top='50%'
                            transform='translateY(-50%)'
                        />
                    </Box>
                    <Box flex="1" />
                    <Center>
                        {user === 'NoUser' ? null : (<>
                            {(compliance?.length > 0) && !user.admin ? 
                                <Badge m={4} fontSize={isMobile ? '2xs' : 'xs'} variant='solid' colorScheme={missingCompliance ? 'red' : 'green'}>
                                    {missingCompliance ? academicProbation ? 'Academic Probation' : 'Out of Compliance' : 'Good Standing'}
                                </Badge> : <Badge m={4} fontSize={isMobile ? '2xs' : 'xs'} variant='solid' colorScheme={'blue'}>
                                    Admin
                                </Badge>}
                            <Menu>
                                <MenuButton
                                    as={Button}
                                    rounded={'full'}
                                    variant={'link'}
                                    cursor={'pointer'}
                                    height='fit-content'
                                    outlineColor={user.admin ? 'white' : missingCompliance ? 'red' : 'limegreen'}
                                    minW={0}>
                                    <Avatar
                                        showBorder={false}
                                        size={'md'}
                                        src={user.iconImage}
                                    />
                                </MenuButton>
                                <MenuList alignItems={'center'}>
                                    <br />
                                    <Center>
                                        <Avatar
                                            size={'xl'}
                                            src={user.iconImage}
                                        />
                                    </Center>
                                    <br />
                                    <Center>
                                        <p>{user.displayName}</p>
                                    </Center>
                                    <br />
                                    <MenuDivider />
                                    <MenuItem as={Link} to={'/'}>Home</MenuItem>
                                    {/* <MenuItem as={Link} to={'/archive'}>Archive</MenuItem> */}
                                    {user.admin === true && <MenuItem as={Link} to={'/overview'}>Overview</MenuItem>}
                                    {user.admin === true && <MenuItem as={Link} to={'/assign'}>Assign</MenuItem>}
                                    {user.admin === true && <MenuItem as={Link} to={'/outreach'}>Outreach</MenuItem>}
                                    {user.admin === true && <MenuItem as={Link} to={'/academicprobation'}>Academic Probation</MenuItem>}
                                    {/* {user.admin === true && <MenuItem as={Link} to={'/edit'}>Edit</MenuItem>} */}
                                    <a href={`${config.API_URL}/auth/logout`}><MenuItem>Logout</MenuItem></a>
                                </MenuList>
                            </Menu>
                        </>
                        )}
                    </Center>
                </Stack>
            </Box >
        </>
    );
}