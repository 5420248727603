import { Flex, Box, Heading } from '@chakra-ui/react';
import React from 'react';

export default function NotFound() {
    return (
        <Box h='calc(100vh - 80px)'>
            <Flex justifyContent="center">
                <Heading>
                    404 Page Not Found
                </Heading>
            </Flex>
        </Box>
    );
}