import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Center, Tab, TabList, TabPanels, Tabs, Text, Container, useDisclosure, VStack, Flex, Heading, SimpleGrid, Avatar, Card, CardHeader, useBreakpointValue } from '@chakra-ui/react';
import { AuthContext } from 'context/auth';
import { DataContext } from 'context/data';
import { AddIcon } from '@chakra-ui/icons';
import AssignToModal from 'components/AssignToModal';
import FailedLoginPage from './FailedLoginPage';

export default function AcademicProbationPage() {
    const { user } = useContext(AuthContext);
    const { topics, members, compliance, getCompliance } = useContext(DataContext);

    const [checkedItemsInProbation, setCheckedItemsInProbation] = useState([]);

    const { isOpen: isOpenAssignToProbation, onOpen: onOpenAssignToProbation, onClose: onCloseAssignToProbation } = useDisclosure();

    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

    useEffect(() => {
        if (compliance.length > 0) {
            setCheckedItemsInProbation(compliance.find(e => e.type === 'Academic Probation').names);
        }
    }, [compliance]);

    const updateAcademicProbationOnClickHandler = () => {
        fetch(`/assignmentsTransactions/updateAcademicProbation`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                academicProbationNames: checkedItemsInProbation
            })
        }).then(response => {
            if (response.status === 200) {
                getCompliance();
                onCloseAssignToProbation();
            }
            return response.json();
        }).catch(err => {
            console.log(err);
        });
    }

    if (user === 'NoUser') {
        return <FailedLoginPage/>
    } else if (!user.admin) {
        return (
            <Center>
                <a style={{ marginTop: '100px' }} >
                    Not an admin
                </a>
            </Center>
        )
    } else {
        return (
            <>
                <AssignToModal isOpen={isOpenAssignToProbation} onClose={onCloseAssignToProbation} members={members} handleOnClick={updateAcademicProbationOnClickHandler}
                    checkedItems={checkedItemsInProbation} setCheckedItems={setCheckedItemsInProbation} altName="Update to Academic Probation" />
                <Box userSelect="none">
                    <Tabs isFitted index={0} size="lg" colorScheme="green" background={"gray.800"} >
                        <Box background={"#3e3e3e"} overflow='auto'
                            position='sticky'
                            top='80px'
                            zIndex='998'
                            css={{
                                '&::-webkit-scrollbar': {
                                    height: '8px',
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    width: '6px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '24px',
                                },
                            }}>
                            <TabList width={"auto"} borderBottom="unset" minWidth={"fit-content"} zIndex={998} position={"sticky"}>
                                <Tab width={`${100 / 1}%`} mb={0} _hover={{ backgroundColor: '#3b473f' }} fontSize={{ base: 'sm', md: 'lg' }} backgroundColor={'#3b473f'}>Academic Probation</Tab>
                                <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: `calc(100% / 1)`,
                                    height: '3px',
                                    background: '#68D391',
                                    borderRadius: '5px',
                                    transition: 'all 350ms ease',
                                    zIndex: '998',
                                    left: `calc((calc(100% / 2) * 0))`
                                }}></div>
                            </TabList>
                        </Box>
                        <TabPanels marginTop='12px' background={"gray.800"} >
                            <Container maxW='5xl'>
                                <VStack spacing={3} alignItems={'flex-start'}>
                                    <Button h={12} backgroundColor="blue.500" rounded="3xl" size='md'
                                        _hover={{ bg: "blue.500" }} _active={{ bg: "blue.500" }} leftIcon={<AddIcon />}
                                        onClick={onOpenAssignToProbation}>
                                        Update
                                    </Button>
                                    <Box p={4} px={isMobile ? 1 : 4} flex="1" w={'100%'}>
                                        <Heading size="lg" m={4} mx={0}>Academic Probation</Heading>
                                        {compliance.find(e => e.type === 'Academic Probation')?.names?.length === 0 ?
                                            <Card align='center'>
                                                <CardHeader p={4}>
                                                    <Flex flex='1' gap='4' flexWrap="nowrap">
                                                        <Box>
                                                            <Heading size='sm'>There's nothing here</Heading>
                                                        </Box>
                                                    </Flex>
                                                </CardHeader>
                                            </Card> :
                                            <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={6}>
                                                {compliance.find(e => e.type === 'Academic Probation')?.names.map((data) => {
                                                    let iconImage = members.length > 0 ? members.find(item => item.name === data)?.iconImage : null;
                                                    return <Card key={data} align='left'>
                                                        <CardHeader p={4}>
                                                            <Flex flex='1' gap='4' flexWrap="nowrap">
                                                                <Avatar name={data.split(' ')[0]} src={iconImage} />
                                                                <Box>
                                                                    <Heading size='sm'>{data}</Heading>
                                                                    <Text color="darkgray">Probation</Text>
                                                                </Box>
                                                            </Flex>
                                                        </CardHeader>
                                                    </Card>
                                                })}
                                            </SimpleGrid>
                                        }
                                    </Box>
                                </VStack>
                            </Container>
                        </TabPanels>
                    </Tabs>
                </Box>
            </>
        );
    }
}
const MenuItemStyle = {
    _hover: { bg: "blackAlpha.200" },
    paddingTop: 3,
    paddingBottom: 3
}