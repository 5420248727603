import { Box, Flex, VStack } from '@chakra-ui/react';
import React from 'react';
import { config } from 'util/constants';
import GoogleButton from 'react-google-button';

export default function FailedLoginPage(props) {
    return (
        <Box h='calc(100vh - 80px)'>
            <Flex justifyContent="center">
                <VStack h='calc(100vh - 80px)'>
                    <Box textAlign={'center'} fontSize={'25px'} fontWeight={'medium'} margin={'0 auto'} width={'100%'}>
                        Please sign in with a RoboTigers account
                    </Box>
                    <a style={{ marginTop: '25px' }} href={props.redirect ? `${config.API_URL}/auth/google?redirect=${props.redirect}` : `${config.API_URL}/auth/google`}>
                        <GoogleButton />
                    </a>
                </VStack>
            </Flex>
        </Box>
    );
}