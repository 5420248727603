import React, { useContext } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import { AuthContext } from 'context/auth';
import FailedLoginPage from 'pages/FailedLoginPage';
import { DataProvider } from 'context/data';
import theme from 'theme';
import NotFound from 'pages/NotFound';
import AssignPage from 'pages/AssignPage';
import Dashboard from 'pages/Dashboard';
import OutreachPage from 'pages/OutreachPage';
import AcademicProbationPage from 'pages/AcademicProbationPage';
import OverviewPage from 'pages/OverviewPage';
import ViewOutreachEvent from 'pages/ViewOutreachEvent';

function App() {
    const { user } = useContext(AuthContext);

    return user === null ? null : user === 'NoUser' ? (
        <ChakraProvider theme={theme}>
            <Router>
                <Navbar />
                <Routes>
                    <Route path='/outreach/:id' element={<ViewOutreachEvent />} />
                    <Route path='/' element={<FailedLoginPage />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Router>
        </ChakraProvider>
    ) : (
        <ChakraProvider theme={theme}>
            <Router>
                <DataProvider user={user}>
                    <Navbar />
                    <Routes>
                        <Route path='/' element={<Dashboard />} />
                        <Route path='/overview' element={<OverviewPage />} />
                        <Route path='/outreach/:id' element={<ViewOutreachEvent />} />
                        {user.admin && <Route path='/outreach' element={<OutreachPage />} />}
                        {user.admin && <Route path='/assign' element={<AssignPage />} />}
                        {user.admin && <Route path='/academicprobation' element={<AcademicProbationPage />} />}
                        {/* {user.admin && <Route path='/edit' element={<EditHomePage />} />} */}
                        <Route path='*' element={<NotFound />} />
                    </Routes>
                </DataProvider>
            </Router>
        </ChakraProvider>
    );
}

export default App;
