import React, { useState, useRef } from "react";
import { Image, Text, Center, Grid, GridItem, Heading, LinkOverlay, LinkBox, Input, Progress, Badge, useToast, Box, Link, HStack, Tag } from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { ExternalLinkIcon } from "@chakra-ui/icons";

export default function GDriveCard(props) {

    let { url, name, assignmentID, uploaded, getAssignments } = props;

    const toast = useToast();

    const browseInputRef = useRef(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileUpload = (event) => {
        setIsUploading(true);
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("parentFolder", url);
        formData.append("assignmentID", assignmentID);

        fetch(`/transactions/uploadgdrive`, {
            method: 'POST',
            body: formData
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error(response.statusText);
            }
        }).then(function (data) {
            toast({
                title: 'Upload complete',
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
            getAssignments();
        }).catch(err => {
            toast({
                title: 'Error uploading file',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
            console.error("Error uploading file:", err);
        }).finally(() => {
            setIsUploading(false);
            browseInputRef.current.value = null;
        });
    };
    return (
        <LinkBox borderWidth='1px' borderColor={uploaded ? 'green.300' : 'rgba(255,255,255,0.16)'} rounded='md' width='100%'>
            <Grid
                mr={2}
                h='60px'
                templateRows='repeat(2, 1fr)'
                templateColumns='repeat(8, 1fr)'
                gap={2}
                rowGap={0}
            >
                <GridItem rowSpan={2} colSpan={1} alignContent="center" onClick={() => { assignmentID && browseInputRef.current.click(); }}
                    width='80px' borderRight="1px solid rgba(255,255,255,0.16)">
                    <Center>
                        <Image maxW={'4.5rem'} maxH='58px' fallback={<AiOutlineCloudUpload color="#3182ce" fontSize={48} />} alt="Upload" />
                    </Center>
                </GridItem>
                <GridItem colSpan={7} alignContent="center" onClick={() => { assignmentID && browseInputRef.current.click(); }}>
                    <Heading size='xs' mt={2} isTruncated={true}>
                        {name}
                    </Heading>
                </GridItem>
                <GridItem colSpan={4} onClick={() => { assignmentID && browseInputRef.current.click(); }}>
                    {assignmentID && <Input ref={browseInputRef} type="file" onChange={handleFileUpload} accept="image/png, image/gif, image/jpeg" display={'none'} />}
                    <HStack>
                        {uploaded ?
                            <Badge colorScheme='blue'>Reupload</Badge> :
                            <Badge colorScheme='blue'>Upload</Badge>}
                    </HStack>
                </GridItem>
                 <GridItem colSpan={3} textAlign={'end'} >
                    {(assignmentID && uploaded?.fileID) && <Link href={`https://drive.google.com/file/d/${uploaded.fileID}`} isTruncated isExternal>
                        <Tag>
                            View <ExternalLinkIcon mx='2px' />
                        </Tag>
                    </Link>}
                </GridItem>
            </Grid>
            {isUploading && <Progress size='xs' colorScheme="green" isIndeterminate />}
        </LinkBox>
    )
}

