import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Center, Tab, TabList, TabPanels, Tabs, MenuButton, Menu, MenuItem, MenuList, MenuDivider, Container, useDisclosure, VStack } from '@chakra-ui/react';
import { DndContext, closestCenter, useSensor, useSensors, TouchSensor, MouseSensor, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { config } from 'util/constants';
import { AuthContext } from 'context/auth';
import { DataContext } from 'context/data';
import TopicCard from 'components/TopicCard';
import { Item } from 'components/Item';
import AddTopicModal from 'components/AddTopicModal';
import AddAssignmentModal from 'components/AddAssignmentModal';
import AlertDialogComponent from 'components/AlertDialogComponent';
import AddResourceModal from 'components/AddResourceModal';
import AddSubgroupModal from 'components/AddSubgroupModal';
import AddSkillModal from 'components/AddSkillModal';
import GoogleButton from 'react-google-button';
import { HiOutlineClipboardList, HiOutlineBookOpen } from "react-icons/hi";
import { AddIcon } from '@chakra-ui/icons';
import { FaRegFolder } from "react-icons/fa";
import { GiUpgrade } from "react-icons/gi";
import SubgroupCard from 'components/SubgroupCard';
import FailedLoginPage from './FailedLoginPage';

export default function AssignPage() {
    const { user } = useContext(AuthContext);
    const { topics, skills, subgroups, members, assignments, getTopics, getAssignments, getSubgroups, getSkills, setTopics, setAssignments, setSkills } = useContext(DataContext);

    const [editTopic, setEditTopic] = useState({ id: null, name: null });
    const [editSubgroup, setEditSubgroup] = useState({ id: null, name: null, members: [] });
    const [alertDialogTitle, setAlertDialogTitle] = useState();
    const [alertDialogFunction, setAlertDialogFunction] = useState(() => () => { });
    const [tabIndex, setTabIndex] = useState(0);
    const [activeId, setActiveId] = useState(null);
    const [presetSubgroup, setPresetSubgroup] = useState(null);
    const itemIds = useMemo(() => topics.map((topic) => topic._id), [topics]);

    const { isOpen: isOpenAddTopic, onOpen: onOpenAddTopic, onClose: onCloseAddTopic } = useDisclosure();
    const { isOpen: isOpenAddAssignment, onOpen: onOpenAddAssignment, onClose: onCloseAddAssignment } = useDisclosure();
    const { isOpen: isOpenAddResource, onOpen: onOpenAddResource, onClose: onCloseAddResource } = useDisclosure();
    const { isOpen: isOpenAlertDialog, onOpen: onOpenAlertDialog, onClose: onCloseAlertDialog } = useDisclosure();

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 0.01,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 8
            },
        })
    );

    const changeTabHandler = (index) => {
        setTabIndex(index);
    }

    if (user === 'NoUser') {
        return <FailedLoginPage/>
    } else if (!user.admin) {
        return (
            <Center>
                <a style={{ marginTop: '100px' }} >
                    Not an admin
                </a>
            </Center>
        )
    } else {
        return (
            <>
                <AlertDialogComponent isOpen={isOpenAlertDialog} onClose={onCloseAlertDialog} title={alertDialogTitle} alertDialogFunction={alertDialogFunction} />

                <Box userSelect="none">
                    <Tabs isFitted index={tabIndex} onChange={changeTabHandler} size="lg" colorScheme="green" background={"gray.800"} >
                        <Box background={"#3e3e3e"} overflow='auto'
                            position='sticky'
                            top='80px'
                            zIndex='998'
                            css={{
                                '&::-webkit-scrollbar': {
                                    height: '8px',
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    width: '6px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '24px',
                                },
                            }}>
                            <TabList width={"auto"} borderBottom="unset" minWidth={"fit-content"} zIndex={998} position={"sticky"}>
                                <Tab width={`${100 / 2}%`} mb={0} _hover={tabIndex === 0 ? { backgroundColor: '#3b473f' } : { backgroundColor: '#4F4F4F' }} fontSize={{ base: 'sm', md: 'lg' }} backgroundColor={tabIndex === 0 ? '#3b473f' : 'unset'}>Assignments/Resources</Tab>
                                <Tab width={`${100 / 2}%`} mb={0} _hover={tabIndex === 1 ? { backgroundColor: '#3b473f' } : { backgroundColor: '#4F4F4F' }} fontSize={{ base: 'sm', md: 'lg' }} backgroundColor={tabIndex === 1 ? '#3b473f' : 'unset'}>Skills</Tab>
                                <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: `calc(100% / 2)`,
                                    height: '3px',
                                    background: '#68D391',
                                    borderRadius: '5px',
                                    transition: 'all 350ms ease',
                                    zIndex: '998',
                                    left: `calc((calc(100% / 2) * ${tabIndex}))`
                                }}></div>
                            </TabList>
                        </Box>
                        <TabPanels marginTop='12px' background={"gray.800"} >
                            <Container maxW='3xl'>
                                {tabIndex === 0 ?
                                    <VStack spacing={3} alignItems={'flex-start'}>
                                        <AddTopicModal isOpen={isOpenAddTopic} onClose={onCloseAddTopic} getTopics={getTopics} editTopic={editTopic} />
                                        <AddAssignmentModal isOpen={isOpenAddAssignment} onClose={onCloseAddAssignment} token={user.accessToken}
                                            topics={topics} members={members} getTopics={getTopics} getAssignments={getAssignments} editTopic={editTopic} setEditTopic={setEditTopic} />
                                        <AddResourceModal isOpen={isOpenAddResource} onClose={onCloseAddResource}
                                            topics={topics} members={members} getTopics={getTopics} getAssignments={getAssignments} editTopic={editTopic} setEditTopic={setEditTopic} />
                                        <Menu>
                                            <MenuButton as={Button} h={12} backgroundColor="blue.500" rounded="3xl" size='md'
                                                _hover={{ bg: "blue.500" }} _active={{ bg: "blue.500" }} leftIcon={<AddIcon />}>
                                                Create
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem icon={<HiOutlineClipboardList fontSize={22} />} sx={MenuItemStyle} onClick={onOpenAddAssignment}>Assignment</MenuItem>
                                                <MenuItem icon={<HiOutlineBookOpen fontSize={22} />} sx={MenuItemStyle} onClick={onOpenAddResource}>Resource</MenuItem>
                                                <MenuDivider />
                                                <MenuItem icon={<FaRegFolder fontSize={20} />} sx={MenuItemStyle}
                                                    onClick={() => { setEditTopic({ id: null, name: null }); onOpenAddTopic(); }}>Topic</MenuItem>
                                            </MenuList>
                                        </Menu>
                                        <DndContext
                                            sensors={sensors}
                                            collisionDetection={closestCenter}
                                            onDragStart={handleDragStart}
                                            onDragEnd={handleDragEnd}
                                            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                                        >
                                            <SortableContext
                                                items={itemIds}
                                                strategy={verticalListSortingStrategy}
                                            >
                                                {topics.map((topicsData) =>
                                                    <TopicCard key={topicsData._id} topicsData={topicsData} id={topicsData._id} topics={topics} getTopics={getTopics} name={topicsData.topicName}
                                                        members={members} editTopic={editTopic} setEditTopic={setEditTopic} onOpenAlertDialog={onOpenAlertDialog} setAssignments={setAssignments}
                                                        setAlertDialogTitle={setAlertDialogTitle} setAlertDialogFunction={setAlertDialogFunction} onOpenAddTopic={onOpenAddTopic}
                                                        getAssignments={getAssignments} assignments={assignments.filter(assignmentsData => assignmentsData.assignmentTopicID === topicsData._id)} />
                                                )}
                                            </SortableContext>
                                            <DragOverlay>
                                                {activeId ? <Item
                                                    // @ts-ignore
                                                    id={activeId} topics={topics} /> : null}
                                            </DragOverlay>
                                        </DndContext>
                                    </VStack>
                                    :
                                    <VStack spacing={3} alignItems={'flex-start'}>
                                        <AddSubgroupModal isOpen={isOpenAddTopic} onClose={onCloseAddTopic} getSubgroups={getSubgroups} editSubgroup={editSubgroup} members={members} />
                                        <AddSkillModal isOpen={isOpenAddResource} onClose={() => { setPresetSubgroup(null); onCloseAddResource(); }} presetSubgroup={presetSubgroup}
                                            subgroups={subgroups} members={members} getSubgroups={getSubgroups} getSkills={getSkills} editSubgroup={editSubgroup} setEditSubgroup={setEditSubgroup} />
                                        <Menu>
                                            <MenuButton as={Button} h={12} backgroundColor="blue.500" rounded="3xl" size='md'
                                                _hover={{ bg: "blue.500" }} _active={{ bg: "blue.500" }} leftIcon={<AddIcon />}>
                                                Create
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem icon={<GiUpgrade fontSize={22} />} sx={MenuItemStyle} onClick={onOpenAddResource}>Skill</MenuItem>
                                                <MenuDivider />
                                                <MenuItem icon={<FaRegFolder fontSize={20} />} sx={MenuItemStyle}
                                                    onClick={() => { setEditSubgroup({ id: null, name: null, members: [] }); onOpenAddTopic(); }}>Subgroup</MenuItem>
                                            </MenuList>
                                        </Menu>
                                        {subgroups.map((subgroupsData) =>
                                            <SubgroupCard key={subgroupsData._id} subgroupMembers={subgroupsData.subgroupMembers} id={subgroupsData._id} subgroups={subgroups} getSubgroups={getSubgroups} name={subgroupsData.subgroupName}
                                                members={members} editSubgroup={editSubgroup} setEditSubgroup={setEditSubgroup} onOpenAlertDialog={onOpenAlertDialog} setSkills={setSkills}
                                                setAlertDialogTitle={setAlertDialogTitle} setAlertDialogFunction={setAlertDialogFunction} onOpenAddSubgroup={onOpenAddTopic}
                                                getSkills={getSkills} skills={skills.filter(skillData => skillData.skillSubgroupID === subgroupsData._id)}
                                                onOpenAddSkill={() => {
                                                    setPresetSubgroup({
                                                        id: subgroupsData._id,
                                                        subgroupName: subgroupsData.subgroupName,
                                                        subgroupMembers: subgroupsData.subgroupMembers
                                                    });
                                                    onOpenAddResource();
                                                }} />
                                        )}
                                    </VStack>}
                            </Container>
                        </TabPanels>
                    </Tabs>
                </Box>
            </>
        );
    }
    function handleDragStart(event) {
        const { active } = event;

        setActiveId(active.id);
    }
    function handleDragEnd(event) {
        const { active, over } = event;
        setActiveId(null);
        if (over && active.id !== over.id) {
            setTopics((items) => {
                const oldIndex = items.findIndex((item) => item._id === active.id);
                const newIndex = items.findIndex((item) => item._id === over.id);

                let movedArray = arrayMove(items, oldIndex, newIndex);
                items[oldIndex].topicIndex = movedArray.findIndex((item) => item._id === active.id);
                items[newIndex].topicIndex = movedArray.findIndex((item) => item._id === over.id);

                fetch(`/assignmentsTransactions/sortTopics`, {
                    method: 'POST',
                    // We convert the React state to JSON and send it as the POST body
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        items: movedArray
                    })
                })
                    .catch(err => {
                        console.log(err);
                    });

                return movedArray;
            });
        }
    }
}

const MenuItemStyle = {
    _hover: { bg: "blackAlpha.200" },
    paddingTop: 3,
    paddingBottom: 3
}