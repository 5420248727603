const prod = {
    API_URL: 'https://hub.robotigers1796.com',
};
const dev = {
    API_URL: 'http://localhost:5000',
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
export const customInputStyle = {
    _placeholder: {
        color: 'gray.200'
    },
    _focus: {
        _placeholder: {
            color: '#3182ce'
        },
        border: 'none',
        borderBottom: '2px solid #3182ce',
        boxShadow: 'none',
    },
    fontSize: '1rem',
    background: 'blackAlpha.500',
    border: 'none',
    borderBottom: '1px solid gray',
    borderRadius: '3',
}
export function isImage(url) {
    return (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
}