// @ts-nocheck
import { Box, Heading } from '@chakra-ui/react';
import React, { forwardRef, useEffect, useState } from 'react';

export const Item = forwardRef(({ id, ...props }, ref) => {

    const [name, setName] = useState();

    const findName = () => {

        if (props.topics) {
            setName(props.topics.find(topic => topic._id === id).topicName);
        } else if (props.assignments) {
            setName(props.assignments.find(assignment => assignment._id === id).assignmentName);
        } else if (props.skills) {
            setName(props.skills.find(skill => skill._id === id).skillName);

        }
    }

    useEffect(() => {
        findName();
    }, [id]);

    return (
        <div {...props} ref={ref}>
            <Box bg={(props.assignments || props.topics) ? "orange.500" : "blue.500"} ml={4} maxW={(props.assignments || props.skills) ? '35%' : '50%'} minH={(props.assignments || props.skills) ? "35px" : "65px"}
                borderRadius={"md"} alignContent={'center'}>
                <Heading size={(props.assignments || props.skills) ? "md" : "lg"} m={4} isTruncated>{name}</Heading>
            </Box>
        </div>
    )
});