import { SimpleGrid, Badge, Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

export default function SkillOverviews(props) {

    const { skill } = props;

    return (
        <Box py={3}>
            <SimpleGrid minChildWidth='200px'>
                <Text isTruncated fontWeight={'bold'}>
                    {skill.skillName}
                </Text>
                <Flex alignContent={'center'} justifySelf={'flex-end'} alignItems={'flex-end'}>
                    {skill.completed ?
                        <Badge variant='solid' colorScheme={'green'} h="fit-content">
                            Signed-Off
                        </Badge>
                        :
                        <Badge variant='solid' colorScheme={'orange'} h="fit-content">
                            Missing
                        </Badge>
                    }</Flex>

            </SimpleGrid>
        </Box>
    )
}
