import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, Input, ModalFooter, Button, useDisclosure, Heading, VStack } from "@chakra-ui/react";
import { customInputStyle } from "util/constants";
import AssignToModal from "./AssignToModal";
import { RiGroupLine } from "react-icons/ri";

export default function AddSubgroupModal(props) {

    const { isOpen, onClose, getSubgroups, setAssignToSubgroup, editSubgroup, members } = props;

    const subgroupNameField = useRef(null);
    const [subgroupNameValue, setSubgroupNameValue] = useState('');
    const [checkedItems, setCheckedItems] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { isOpen: isOpenAssignTo, onOpen: onOpenAssignTo, onClose: onCloseAssignTo } = useDisclosure();

    const handleSubgroupNameChange = (event) => setSubgroupNameValue(event.target.value);

    useEffect(() => {
        if (editSubgroup.name) {
            setSubgroupNameValue(editSubgroup.name);
            setCheckedItems(editSubgroup.members);
        }
    }, [editSubgroup]);

    const resetModal = () => {
        subgroupNameField.current.value = '';
        setSubgroupNameValue('');
        setCheckedItems([]);
    }

    const createSubgroupOnClickHandler = () => {
        setIsSubmitting(true);
        fetch(`/assignmentsTransactions/createSubgroup`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                subgroupName: subgroupNameField.current.value,
                subgroupMembers: checkedItems
            })
        }).then(response => {
            if (response.status === 200) {
                onClose();
                setSubgroupNameValue('');
                setCheckedItems([]);
                getSubgroups();
                return response.json();
            }
            return response.text();
        }).then(response => {
            if (setAssignToSubgroup && response._id)
                setAssignToSubgroup({ _id: response._id, subgroupName: response.subgroupName });
        }).catch(err => {
            console.log(err);
        }).finally(() =>
            setIsSubmitting(false));
    }

    const editSubgroupOnClickHandler = () => {
        setIsSubmitting(true);
        fetch(`/assignmentsTransactions/editSubgroup`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                subgroupName: subgroupNameField.current.value,
                subgroupMembers: checkedItems,
                id: editSubgroup.id
            })
        }).then(response => {
            if (response.status === 200) {
                onClose();
                setSubgroupNameValue('');
                setCheckedItems([]);
                getSubgroups();
                return response.json();
            }
            return response.text();
        }).then(response => {
            if (setAssignToSubgroup && response._id)
                setAssignToSubgroup({ _id: response._id, subgroupName: response.subgroupName });
        }).catch(err => {
            console.log(err);
        }).finally(() =>
            setIsSubmitting(false));
    }

    return (
        <>
            <AssignToModal isOpen={isOpenAssignTo} onClose={onCloseAssignTo} members={members} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
            <Modal
                initialFocusRef={subgroupNameField}
                isOpen={isOpen}
                onClose={() => { onClose(); resetModal(); }}
                size="xs"
                isCentered
                blockScrollOnMount={false}
            >
                <ModalOverlay />
                <ModalContent userSelect="none">
                    <ModalHeader pb={0}>{editSubgroup.id !== null ? 'Edit' : 'Add'} subgroup</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={2}>
                        <VStack alignItems={'flex-start'}>
                            <FormControl>
                                <Input ref={subgroupNameField} sx={customInputStyle} autoComplete='off' defaultValue={editSubgroup.name} size="lg" placeholder='Subgroup'
                                    onChange={handleSubgroupNameChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter' && subgroupNameValue.length !== 0 && !isSubmitting) {
                                            (editSubgroup.id !== null ? editSubgroupOnClickHandler() : createSubgroupOnClickHandler())
                                        }
                                    }} />
                            </FormControl>
                            <Heading size='sm'>Members</Heading>
                            <Button leftIcon={<RiGroupLine />} w="100%" fontSize={{ base: "sm", md: "lg" }} size="lg" variant="outline" color="blue.300" onClick={onOpenAssignTo}>
                                {checkedItems.length === members?.length ? 'All members' : `${checkedItems.length} member${checkedItems.length !== 1 ? 's' : ''}`}
                            </Button>
                        </VStack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' ml={3} onClick={editSubgroup.id !== null ? editSubgroupOnClickHandler : createSubgroupOnClickHandler}
                            isDisabled={subgroupNameValue.length === 0 || isSubmitting}>
                            {editSubgroup.id !== null ? 'Edit' : 'Add'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
