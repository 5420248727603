import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalOverlay, ModalContent, Box, ModalHeader, Card, CardBody, Heading, Flex, Textarea, ModalBody, IconButton, FormControl, VStack, Input, Button, Text, HStack, Icon, Divider, Avatar, Center, useDisclosure, Tooltip, useBreakpointValue, Stack, useToast, Progress, useClipboard, InputGroup, InputRightElement } from "@chakra-ui/react";
import { CloseIcon, QuestionOutlineIcon } from "@chakra-ui/icons";
import { AiOutlineUpload } from "react-icons/ai";
import { IoMdCalendar, IoMdLink } from "react-icons/io";
import AddLinkModal from "./AddLinkModal";
import LinkCard from "./LinkCard";
import { customInputStyle } from "util/constants";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList } from "@choc-ui/chakra-autocomplete";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { RiGroupLine } from "react-icons/ri";
import AssignToModal from "./AssignToModal";
dayjs.extend(utc);

export default function AddOutreachModal(props) {
    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
    const { isOpen, onClose, members, getOutreachEvents, editOutreachEvent } = props;
    const { isOpen: isOpenAddLink, onOpen: onOpenAddLink, onClose: onCloseAddLink } = useDisclosure();
    const { isOpen: isOpenAssignTo, onOpen: onOpenAssignTo, onClose: onCloseAssignTo } = useDisclosure();

    const [checkedItems, setCheckedItems] = useState([]);
    const [outreachEventName, setOutreachEventName] = useState('');
    const [outreachEventInstructions, setOutreachEventInstructions] = useState('');
    const [outreachEventLinks, setOutreachEventLinks] = useState([]);
    const [outreachEventHours, setOutreachEventHours] = useState('1.00');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [startTime, setStartTime] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState({ address: null, lat: 40.7419, lng: -73.9279 });

    const titleRef = useRef(null);
    const instructionsRef = useRef(null);
    const browseInputRef = useRef(null);
    const startTimeRef = useRef(null);
    const hoursRef = useRef(null);

    const [zoomLevel, setZoomLevel] = useState(12);

    const [libraries] = useState(['places']);
    const toast = useToast();

    const {
        setValue,
        suggestions: { data },
        clearSuggestions,
    } = usePlacesAutocomplete({ debounce: 200 });

    useEffect(() => {
        if (editOutreachEvent && isOpen) {
            setOutreachEventName(editOutreachEvent.outreachEventName);
            setOutreachEventInstructions(editOutreachEvent.outreachEventInstructions);
            setOutreachEventLinks(editOutreachEvent.outreachEventLinks);
            setOutreachEventHours(editOutreachEvent.outreachTimeHours.toFixed(2));
            setCheckedItems(editOutreachEvent.completed);
            handleSelect(editOutreachEvent.outreachEventAddress);
            if (editOutreachEvent.outreachEventDateTime) {
                setStartTime(dayjs(editOutreachEvent.outreachEventDateTime).format('YYYY-MM-DDTHH:mm'));
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (typeof google !== 'object') {
            const result = useJsApiLoader({
                googleMapsApiKey: 'AIzaSyBb5rJ23Aw6iPpdww0pYA69W5tKRMjKSIE',
                // @ts-ignore
                libraries: libraries,
            });
            setIsLoaded(result.isLoaded);
        } else {
            setIsLoaded(true);
        }
    }, []);

    const resetModal = () => {
        titleRef.current.value = '';
        setOutreachEventName('');
        setOutreachEventInstructions('');
        setOutreachEventLinks([]);
        setCheckedItems([]);
        setOutreachEventHours('1.00');
        setStartTime('');
        setSelectedPlace({ address: null, lat: 40.7419, lng: -73.9279 });
    }

    const createOutreachEventOnClickHandler = () => {
        setIsSubmitting(true);
        let eventDateUTC = startTimeRef.current.value ? dayjs(startTimeRef.current.value).utc().format() : null;
        fetch(`/assignmentsTransactions/createOutreachEvent`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                outreachEventName: titleRef.current.value,
                outreachEventInstructions: instructionsRef.current.value,
                outreachEventLinks: outreachEventLinks,
                outreachEventDateTime: eventDateUTC,
                outreachTimeHours: outreachEventHours,
                outreachEventAddress: selectedPlace.address,
                completed: checkedItems,
                outreachEventCoords: [selectedPlace.lat, selectedPlace.lng]
            })
        }).then(response => {
            if (response.status === 200) {
                onClose();
                resetModal();
                getOutreachEvents();
            }
            return response.json();
            // @ts-ignore
        }).then(function (data) {
            // (data) && console.log(data);
            toast({
                title: "Outreach Event Posted",
                duration: 20000,
                status: 'success',
                colorScheme: 'gray',
                description: <Flex alignItems="flex-end" pt={4}>
                    <Input
                        color='gray.900'
                        borderColor='gray.700'
                        borderRadius={6}
                        _placeholder={{ color: 'inherit' }}
                        size='sm'
                        isDisabled
                        _hover={{ cursor: 'default' }}
                        value={`https://hub.robotigers1796.com/outreach/${data._id}`}
                        mr={2}
                    />
                    <Button onClick={() => { navigator.clipboard.writeText(`https://hub.robotigers1796.com/outreach/${data._id}`) }} colorScheme='blue' size='sm'>Copy</Button>
                </Flex>,
                position: 'top',
                isClosable: true,
            });
        }).catch(err => {
            console.log(err);
        }).finally(() =>
            setIsSubmitting(false));
    }

    const editOutreachEventOnClickHandler = () => {
        setIsSubmitting(true);
        let eventDateUTC = startTimeRef.current.value ? dayjs(startTimeRef.current.value).utc().format() : null;
        fetch(`/assignmentsTransactions/editOutreachEvent`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: editOutreachEvent.id,
                outreachEventName: titleRef.current.value,
                outreachEventInstructions: instructionsRef.current.value,
                outreachEventLinks: outreachEventLinks,
                outreachEventDateTime: eventDateUTC,
                outreachTimeHours: outreachEventHours,
                outreachEventAddress: selectedPlace.address,
                completed: checkedItems,
                outreachEventCoords: [selectedPlace.lat, selectedPlace.lng]
            })
        }).then(response => {
            if (response.status === 200) {
                onClose();
                resetModal();
                getOutreachEvents();
            }
            return response.text();
        }).then(function (data) {
            (data) && console.log(data);
        }).catch(err => {
            console.log(err);
        }).finally(() =>
            setIsSubmitting(false));
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("photo", file);

        fetch(`/transactions/upload`, {
            method: 'POST',
            body: formData
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            return response.text();
        }).then(function (data) {
            if (data) {
                setOutreachEventLinks(outreachEventLinks => [...outreachEventLinks, data.imageUrl]);
            }
        }).catch(err => {
            console.error("Error uploading file:", err);
        });

    };

    return (
        <>
            <AddLinkModal isOpen={isOpenAddLink} onClose={onCloseAddLink} setAssignmentLinks={setOutreachEventLinks} />
            <AssignToModal isOpen={isOpenAssignTo} onClose={onCloseAssignTo} members={members} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
            <Modal
                initialFocusRef={titleRef}
                isOpen={isOpen}
                onClose={() => { onClose(); resetModal(); }}
                size="full"
                blockScrollOnMount={false}
            >
                <ModalOverlay />
                <ModalContent userSelect="none" overflowX='hidden'>
                    <ModalHeader>
                        <HStack spacing='12px'>
                            <IconButton
                                isRound={true}
                                aria-label="Close"
                                icon={<CloseIcon />}
                                onClick={() => { onClose(); resetModal(); }}
                                position="relative"
                                marginRight={4}
                                backgroundColor="transparent"
                            />
                            <Avatar background="teal.100" w="2.5rem" h="2.5rem" icon={<Icon as={IoMdCalendar} w={7} h={7} color='green.600' />} />
                            <Text fontSize={{ base: "lg", md: "3xl" }}>Outreach Event</Text>
                            <Box flex="1" />
                            <Button colorScheme='blue' variant='solid' onClick={editOutreachEvent ? editOutreachEventOnClickHandler : createOutreachEventOnClickHandler}
                                isDisabled={outreachEventName === '' || !selectedPlace.address || !startTime || isSubmitting}>
                                {editOutreachEvent ? 'Edit' : 'Post'}
                            </Button>
                        </HStack>
                    </ModalHeader>
                    <Divider />
                    <ModalBody py={0} pl={{ base: 1, md: 6 }} pr={0} display="flex" backgroundColor="blackAlpha.400">
                        <Flex flex="1">
                            <Box py={4} p={{ base: 1, md: 4 }} mr={{ base: 0, md: 6 }} flex="1" width="100%">
                                <Card mt={{ base: 0, md: 3 }} maxW='4xl' mx="auto" border="1px solid rgba(255,255,255,0.16)">
                                    <CardBody>
                                        <FormControl>
                                            <VStack spacing={4}>
                                                <Input ref={titleRef} autoComplete='off' size="lg" defaultValue={editOutreachEvent ? editOutreachEvent.outreachEventName : ''}
                                                    placeholder='Outreach Event Name' onChange={(data) => setOutreachEventName(data.target.value)} isRequired sx={customInputStyle} />
                                                <Textarea ref={instructionsRef} defaultValue={editOutreachEvent ? outreachEventInstructions : ''} minHeight={'8rem'} size="lg"
                                                    placeholder='Instructions (Optional)' sx={customInputStyle} />
                                            </VStack>
                                            {outreachEventLinks.map(link => {
                                                return (<HStack key={link} mt={2}>
                                                    <LinkCard url={link} />
                                                    <IconButton aria-label="Delete link" icon={<CloseIcon />} size="xs" alignSelf='flex-start' onClick={() => {
                                                        setOutreachEventLinks((oldValues) =>
                                                            oldValues.filter(outreachEventLinks => outreachEventLinks !== link)
                                                        )
                                                    }} />
                                                </HStack>)
                                            })}
                                        </FormControl>
                                    </CardBody>
                                </Card>
                                <Card mt={3} maxW='4xl' mx="auto" border="1px solid rgba(255,255,255,0.16)">
                                    <CardBody>
                                        <Box>
                                            <Heading size='xs'>
                                                Attach
                                            </Heading>
                                            <Center>
                                                <HStack pt='2' spacing={8} fontSize='sm'>
                                                    <VStack spacing={2}>
                                                        <Input ref={browseInputRef} type="file" onChange={handleFileUpload} accept="image/png, image/gif, image/jpeg" display={'none'} />
                                                        <IconButton aria-label='Upload'
                                                            isRound={true}
                                                            size="lg"
                                                            variant='outline'
                                                            colorScheme='white'
                                                            fontSize='28px'
                                                            icon={<AiOutlineUpload />}
                                                            onClick={() => { browseInputRef.current.click(); }}
                                                        />
                                                        <Heading size='xs'>Upload</Heading>
                                                    </VStack>
                                                    <VStack spacing={2}>
                                                        <IconButton aria-label='Link'
                                                            isRound={true}
                                                            size="lg"
                                                            variant='outline'
                                                            colorScheme='white'
                                                            fontSize='28px'
                                                            icon={<IoMdLink />}
                                                            onClick={onOpenAddLink}
                                                        />
                                                        <Heading size='xs'>Link</Heading>
                                                    </VStack>
                                                </HStack>
                                            </Center>
                                        </Box>
                                    </CardBody>
                                </Card>
                                {!isLoaded ? (
                                    <h3>Loading.....</h3>
                                ) : (
                                    <Card mt={3} maxW='4xl' mx="auto" border="1px solid rgba(255,255,255,0.16)">
                                        <CardBody>
                                            <Box>
                                                <Heading size='xs' mb={2}>
                                                    Location
                                                </Heading>
                                                <GoogleMap
                                                    mapContainerStyle={{ height: '300px', width: '100%' }}
                                                    center={selectedPlace}
                                                    zoom={zoomLevel}
                                                    options={{ disableDefaultUI: true }}
                                                >
                                                    <MarkerF position={selectedPlace} />
                                                </GoogleMap>
                                            </Box>
                                        </CardBody>
                                    </Card>
                                )}
                            </Box>
                            <Box p={4} bg="gray.700" w={{ base: '150px', md: '340px' }} borderLeft="1px solid rgba(255,255,255,0.16)">
                                <FormControl>
                                    <VStack spacing={4} alignItems={'normal'}>
                                        <Tooltip label="Members can check-in up to an hour before this time." fontSize={'xs'} aria-label='Outreach Event Time Start'>
                                            <Heading size='xs'>Outreach Event Start Date & Time</Heading>
                                        </Tooltip>
                                        <Input
                                            ref={startTimeRef}
                                            type="datetime-local"
                                            defaultValue={startTime}
                                            autoComplete='off'
                                            onChange={e => setStartTime(e.target.value)}
                                        />
                                        <Heading size='xs'>Outreach Event Hours</Heading>
                                        <Stack direction={isMobile ? "column" : "row"} maxW='320px'>
                                            <Button onClick={() => {
                                                // @ts-ignore
                                                setOutreachEventHours(oldValue => {
                                                    let value = parseFloat(oldValue);
                                                    const newValue = value > 0.0 ? value -= 0.50 : value;
                                                    return newValue.toFixed(2);
                                                })
                                            }}>-</Button>
                                            <Input ref={hoursRef} onChange={e => {
                                                let value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                                                setOutreachEventHours(value)
                                            }
                                            } value={outreachEventHours} />
                                            <Button onClick={() => {
                                                // @ts-ignore
                                                setOutreachEventHours(oldValue => {
                                                    let value = parseFloat(oldValue);
                                                    const newValue = value < 24.0 ? value += 0.50 : value;
                                                    return newValue.toFixed(2);
                                                })
                                            }}>+</Button>
                                        </Stack>
                                        <Heading size='xs'>Outreach Event Address</Heading>
                                        {(!isLoaded || (editOutreachEvent && !selectedPlace.address)) ? (
                                            <h3>Loading.....</h3>
                                        ) : (
                                            <AutoComplete onChange={(e) => handleSelect(e)} defaultValue={selectedPlace.address}>
                                                <AutoCompleteInput placeholder="Location" variant="outline"
                                                    onChange={(e) => setValue(e.target.value)} autoComplete="off" />
                                                <AutoCompleteList>
                                                    {data.map((description, place_id) => (
                                                        <AutoCompleteItem
                                                            key={place_id}
                                                            value={description}
                                                            textTransform="capitalize"
                                                        />
                                                    ))}
                                                </AutoCompleteList>
                                            </AutoComplete>
                                        )}
                                        <Heading size='xs'>
                                            Attending (Optional)
                                            <Tooltip label='Members that are not checked in here will need to check in to the event with the generated link.'>
                                                <QuestionOutlineIcon boxSize={4} mx={2} mb={'1px'} />
                                            </Tooltip>
                                        </Heading>
                                        <Button leftIcon={<RiGroupLine />} fontSize={{ base: "sm", md: "lg" }} size="lg" variant="outline" color="blue.300" onClick={onOpenAssignTo}>
                                            {checkedItems.length === members.length ? 'All members' : `${checkedItems.length} member${checkedItems.length !== 1 ? 's' : ''}`}
                                        </Button>
                                    </VStack>
                                </FormControl>
                            </Box>
                        </Flex>
                    </ModalBody>
                </ModalContent >
            </Modal >
        </>
    )

    async function handleSelect(address) {
        setValue(address, false);
        clearSuggestions();
        try {
            const result = await getGeocode({ address }); //get geocoding object
            const { lat, lng } = await getLatLng(result[0]);
            setSelectedPlace({ address: address, lat: lat, lng: lng });
            setZoomLevel(16);
        } catch (e) {
            console.log(e);
        }
    };
}