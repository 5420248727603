import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalOverlay, ModalContent, Box, ModalHeader, Card, CardBody, CardHeader, Heading, Flex, Textarea, ModalBody, IconButton, FormControl, VStack, Input, ModalFooter, Button, Text, HStack, Icon, Divider, Avatar, Center, Menu, MenuButton, MenuDivider, MenuItem, MenuList, useDisclosure } from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { HiOutlineBookOpen } from "react-icons/hi";
import { AiOutlineUpload } from "react-icons/ai";
import { RiGroupLine } from "react-icons/ri";
import { IoMdLink } from "react-icons/io";
import AddTopicModal from "./AddTopicModal";
import AssignToModal from "./AssignToModal";
import AddLinkModal from "./AddLinkModal";
import LinkCard from "./LinkCard";
import { customInputStyle } from "util/constants";

export default function AddResourceModal(props) {

    const { isOpen, onClose, members, topics, getTopics, getAssignments, editTopic, setEditTopic, editAssignment, topicName } = props;
    const { isOpen: isOpenAddTopic, onOpen: onOpenAddTopic, onClose: onCloseAddTopic } = useDisclosure();
    const { isOpen: isOpenAddLink, onOpen: onOpenAddLink, onClose: onCloseAddLink } = useDisclosure();
    const { isOpen: isOpenAssignTo, onOpen: onOpenAssignTo, onClose: onCloseAssignTo } = useDisclosure();

    const [assignToTopic, setAssignToTopic] = useState({ _id: null, topicName: 'No Topic' });
    const [checkedItems, setCheckedItems] = useState([]);
    const [assignmentName, setAssignmentName] = useState('');
    const [assignmentInstructions, setAssignmentInstructions] = useState('');
    const [assignmentLinks, setAssignmentLinks] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const titleRef = useRef(null);
    const instructionsRef = useRef(null);
    const browseInputRef = useRef(null);

    useEffect(() => {
        if (editAssignment && isOpen) {
            setAssignmentName(editAssignment.assignmentName);
            setCheckedItems(editAssignment.assigned);
            setAssignToTopic({ _id: editAssignment.assignmentTopicID, topicName: topicName });
            setAssignmentInstructions(editAssignment.assignmentInstructions);
            setAssignmentLinks(editAssignment.assignmentLinks);
        } else {
            setCheckedItems(members.map(data => { return data.name }));
        }
    }, [isOpen]);

    const resetModal = () => {
        titleRef.current.value = '';
        setAssignmentName('');
        setCheckedItems([]);
        setAssignToTopic({ _id: null, topicName: 'No Topic' });
        setAssignmentInstructions('');
        setAssignmentLinks([]);
    }

    const createAssignmentOnClickHandler = () => {
        setIsSubmitting(true);
        fetch(`/assignmentsTransactions/createAssignment`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                assignmentName: titleRef.current.value,
                assignmentTopicID: assignToTopic._id,
                assignmentInstructions: instructionsRef.current.value,
                assignmentLinks: assignmentLinks,
                assigned: checkedItems,
                resource: true
            })
        }).then(response => {
            if (response.status === 200) {
                onClose();
                resetModal();
                getAssignments();
            }
            return response.text();
        }).then(function (data) {
            // (data) && console.log(data);
        }).catch(err => {
            console.log(err);
        }).finally(() =>
            setIsSubmitting(false));
    }

    const editAssignmentOnClickHandler = () => {
        setIsSubmitting(true);
        fetch(`/assignmentsTransactions/editAssignment`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: editAssignment.id,
                assignmentName: titleRef.current.value,
                assignmentTopicID: assignToTopic._id,
                assignmentInstructions: instructionsRef.current.value,
                assignmentLinks: assignmentLinks,
                assigned: checkedItems
            })
        }).then(response => {
            if (response.status === 200) {
                onClose();
                resetModal();
                getAssignments();
            }
            return response.text();
        }).then(function (data) {
            (data) && console.log(data);
        }).catch(err => {
            console.log(err);
        }).finally(() =>
            setIsSubmitting(false));
    }

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("photo", file);

        fetch(`/transactions/upload`, {
            method: 'POST',
            body: formData
        }).then(response => {
            if (response.status === 200) {
                return response.json();
            }
            return response.text();
        }).then(function (data) {
            if (data) {
                setAssignmentLinks(assignmentLinks => [...assignmentLinks, data.imageUrl]);
            }
        })
            .catch(err => {
                console.error("Error uploading file:", err);
            });

    };

    return (
        <>
            <AddTopicModal isOpen={isOpenAddTopic} onClose={onCloseAddTopic} getTopics={getTopics} setAssignToTopic={setAssignToTopic} editTopic={editTopic} />
            <AssignToModal isOpen={isOpenAssignTo} onClose={onCloseAssignTo} members={members} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
            <AddLinkModal isOpen={isOpenAddLink} onClose={onCloseAddLink} setAssignmentLinks={setAssignmentLinks} />

            <Modal
                initialFocusRef={titleRef}
                isOpen={isOpen}
                onClose={() => { onClose(); resetModal(); }}
                size="full"
                blockScrollOnMount={false}
            >
                <ModalOverlay />
                <ModalContent userSelect="none" overflowX='hidden'>
                    <ModalHeader>
                        <HStack spacing='12px'>
                            <IconButton
                                isRound={true}
                                aria-label="Close"
                                icon={<CloseIcon />}
                                onClick={() => { onClose(); resetModal(); }}
                                position="relative"
                                marginRight={4}
                                backgroundColor="transparent"
                            />
                            <Avatar background="red.100" w="2.5rem" h="2.5rem" icon={<Icon as={HiOutlineBookOpen} w={7} h={7} color='orange.500' />} />
                            <Text fontSize={{ base: "lg", md: "3xl" }}>Resource</Text>
                            <Box flex="1" />
                            <Button colorScheme='blue' variant='solid' onClick={editAssignment ? editAssignmentOnClickHandler : createAssignmentOnClickHandler}
                                isDisabled={assignmentName === '' || checkedItems.length === 0 || assignToTopic._id === null || isSubmitting}>
                                {editAssignment ? 'Edit' : 'Post'}
                            </Button>
                        </HStack>
                    </ModalHeader>
                    <Divider />
                    <ModalBody py={0} pl={{ base: 1, md: 6 }} pr={0} display="flex" backgroundColor="blackAlpha.400">
                        <Flex flex="1">
                            <Box py={4} p={{ base: 1, md: 4 }} mr={{ base: 0, md: 6 }} flex="1" width="100%">
                                <Card mt={{ base: 0, md: 3 }} maxW='4xl' mx="auto" border="1px solid rgba(255,255,255,0.16)">
                                    <CardBody>
                                        <FormControl>
                                            <VStack spacing={4}>
                                                <Input ref={titleRef} autoComplete='off' size="lg" defaultValue={editAssignment ? editAssignment.assignmentName : ''} placeholder='Resource Title' onChange={(data) => setAssignmentName(data.target.value)} isRequired sx={customInputStyle} />
                                                <Textarea ref={instructionsRef} defaultValue={editAssignment ? assignmentInstructions : ''} minHeight={'8rem'} size="lg" placeholder='Instructions (Optional)' sx={customInputStyle} />
                                            </VStack>
                                            {assignmentLinks.map(link => {
                                                return (<HStack key={link} mt={2}>
                                                    <LinkCard url={link} />
                                                    <IconButton aria-label="Delete link" icon={<CloseIcon />} size="xs" alignSelf='flex-start' onClick={() => {
                                                        setAssignmentLinks((oldValues) =>
                                                            oldValues.filter(assignmentLinks => assignmentLinks !== link)
                                                        )
                                                    }} />
                                                </HStack>)
                                            })}
                                        </FormControl>
                                    </CardBody>
                                </Card>
                                <Card mt={3} maxW='4xl' mx="auto" border="1px solid rgba(255,255,255,0.16)">
                                    <CardBody>
                                        <Box>
                                            <Heading size='xs'>
                                                Attach
                                            </Heading>
                                            <Center>
                                                <HStack pt='2' spacing={8} fontSize='sm'>
                                                    <VStack spacing={2}>
                                                        <Input ref={browseInputRef} type="file" onChange={handleFileUpload} accept="image/png, image/gif, image/jpeg" display={'none'} />
                                                        <IconButton aria-label='Upload'
                                                            isRound={true}
                                                            size="lg"
                                                            variant='outline'
                                                            colorScheme='white'
                                                            fontSize='28px'
                                                            icon={<AiOutlineUpload />}
                                                            onClick={() => { browseInputRef.current.click(); }}
                                                        />
                                                        <Heading size='xs'>Upload</Heading>
                                                    </VStack>
                                                    <VStack spacing={2}>
                                                        <IconButton aria-label='Link'
                                                            isRound={true}
                                                            size="lg"
                                                            variant='outline'
                                                            colorScheme='white'
                                                            fontSize='28px'
                                                            icon={<IoMdLink />}
                                                            onClick={onOpenAddLink}
                                                        />
                                                        <Heading size='xs'>Link</Heading>
                                                    </VStack>
                                                </HStack></Center>
                                        </Box>
                                    </CardBody>
                                </Card>
                            </Box>
                            <Box p={4} bg="gray.700" w={{ base: '150px', md: '340px' }} borderLeft="1px solid rgba(255,255,255,0.16)">
                                <FormControl>
                                    <VStack spacing={4} alignItems={'normal'}>
                                        <Heading size='xs'>Assign to</Heading>
                                        <Button leftIcon={<RiGroupLine />} fontSize={{ base: "sm", md: "lg" }} size="lg" variant="outline" color="blue.300" onClick={onOpenAssignTo}>
                                            {checkedItems.length === members.length ? 'All members' : `${checkedItems.length} member${checkedItems.length !== 1 ? 's' : ''}`}
                                        </Button>
                                        <Heading size='xs'>Topic</Heading>
                                        <Menu>
                                            <MenuButton
                                                px={4}
                                                py={2}
                                                transition='all 0.2s'
                                                borderRadius='md'
                                                borderWidth='1px'
                                                _hover={{ bg: 'gray.400' }}
                                                _expanded={{ bg: 'blue.400' }}
                                                _focus={{ boxShadow: 'outline' }}
                                            >
                                                <HStack>
                                                    <Box fontSize={{ base: "sm", md: "lg" }}>{assignToTopic.topicName}</Box>
                                                    <Box flex="1" />
                                                    <ChevronDownIcon />
                                                </HStack>
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => { setEditTopic({ id: null, name: null }); onOpenAddTopic(); }}>Create topic</MenuItem>
                                                <MenuDivider />
                                                {topics?.map((data) =>
                                                    <MenuItem key={data._id} onClick={() => setAssignToTopic(data)}>{data.topicName}</MenuItem>
                                                )}
                                            </MenuList>
                                        </Menu>
                                    </VStack>
                                </FormControl>
                            </Box>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}