import { Card, CardHeader, HStack, Heading, Menu, MenuButton, IconButton, MenuList, MenuItem, Divider, CardBody, Avatar, Icon, Box, Accordion } from "@chakra-ui/react";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useContext } from "react";
import { AuthContext } from "context/auth";
import SkillCard from "./SkillCard";
import { DndContext, closestCenter, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis, restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Item } from "./Item";
import { useState, useMemo } from "react";

export default function SubgroupCard(props) {
    const { user } = useContext(AuthContext);
    let { id, name, subgroups, skills, members, onOpenAlertDialog, setAlertDialogTitle, setAlertDialogFunction,
        getSubgroups, setEditSubgroup, subgroupMembers, onOpenAddSubgroup, getSkills, editSubgroup, setSkills, onOpenAddSkill } = props;
    const [activeId, setActiveId] = useState(null);
    const itemIds = useMemo(() => skills.map((skill) => skill._id), [skills]);
    const mapSkills = useMemo(() => skills.map(skillData => {
        if (skillData.assigned.includes(user.displayName) || user.admin) {
            return <SkillCard key={skillData._id} user={user} skillData={skillData} subgroupName={name} members={members} subgroups={subgroups} getSubgroups={getSubgroups} getSkills={getSkills}
                editSubgroup={editSubgroup} setEditSubgroup={setEditSubgroup}
                setAlertDialogFunction={setAlertDialogFunction} setAlertDialogTitle={setAlertDialogTitle} onOpenAlertDialog={onOpenAlertDialog} />
        }
    }
    ), [skills]);
    
    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 4
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 8
            },
        })
    );

    const deleteOnClickHandler = () => {
        setAlertDialogFunction(() => () => {
            fetch(`/assignmentsTransactions/deleteSubgroup`, {
                method: 'POST',
                // We convert the React state to JSON and send it as the POST body
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    _id: id
                })
            }).then(response => {
                if (response.status === 200) {
                    getSubgroups();
                }
                return response.text();
            }).then(function (data) {
                (data) && console.log(data);
            }).catch(err => {
                console.log(err);
            });
        });

        setAlertDialogTitle('Delete Subgroup');
        onOpenAlertDialog();
    }

    return (
        <Card width="100%" mb={2} align='left' variant="filled" bg="transparent"
            _hover={{
                outline: "1px solid rgba(255,255,255, 0.16)"
            }}
        >
            <CardHeader py={3}>
                <HStack>
                    <Heading size='lg' _hover={{
                        textDecoration: 'underline',
                        color: '#3182ce'
                    }}> {name}</Heading>
                    <Box flex="1" />
                    {(setEditSubgroup && onOpenAddSubgroup) &&
                        <Menu>
                            <MenuButton as={IconButton}
                                aria-label='menu' bg="transparent" size="lg" fontSize='22px' isRound icon={<BsThreeDotsVertical />}
                            />
                            <MenuList minWidth={'8rem'} >
                                <MenuItem px={3} onClick={onOpenAddSkill}>
                                    Add Skill
                                </MenuItem>
                                <Divider />
                                <MenuItem px={3} onClick={() => { setEditSubgroup({ id: id, name: name, members: subgroupMembers }); onOpenAddSubgroup(); }}>
                                    Edit Subgroup
                                </MenuItem >
                                <MenuItem px={3} onClick={deleteOnClickHandler}>
                                    Delete Subgroup
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    }
                </HStack>
            </CardHeader>
            <Accordion allowToggle>
                {location.pathname === '/assign' ?
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    >
                        <SortableContext
                            items={itemIds}
                            strategy={verticalListSortingStrategy}
                        >
                            {mapSkills}
                        </SortableContext>
                        <DragOverlay>
                            {activeId ? <Item
                                // @ts-ignore
                                id={activeId} skills={skills} /> : null}
                        </DragOverlay>
                    </DndContext> :
                    mapSkills
                }
            </Accordion>
        </Card>
    )
    function handleDragStart(event) {
        const { active } = event;

        setActiveId(active.id);
    }
    function handleDragEnd(event) {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            setSkills((items) => {
                const oldIndex = items.findIndex((item) => item._id === active.id);
                const newIndex = items.findIndex((item) => item._id === over.id);
                let movedArray = arrayMove(items, oldIndex, newIndex);

                items[oldIndex].assignmentIndex = movedArray.findIndex((item) => item._id === active.id);
                items[newIndex].assignmentIndex = movedArray.findIndex((item) => item._id === over.id);

                fetch(`/assignmentsTransactions/sortSkills`, {
                    method: 'POST',
                    // We convert the React state to JSON and send it as the POST body
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        items: movedArray
                    })
                })
                    .catch(err => {
                        console.log(err);
                    });
                return movedArray;
            });
        }
    }
}