import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from 'context/auth';
import { Box, Card, CardBody, Center, Text, Tabs, TabList, TabPanels, Tab, Container, VStack, HStack, CardHeader, Heading, Flex, Divider, Spinner, useBreakpointValue, Stack, SimpleGrid, Button } from '@chakra-ui/react';
import { DataContext } from 'context/data';
import TopicCard from 'components/TopicCard';
import { IoMdTime } from "react-icons/io";
import { HiOutlineClipboardList } from "react-icons/hi";
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { GiUpgrade } from 'react-icons/gi';
import SubgroupCard from 'components/SubgroupCard';
import FailedLoginPage from './FailedLoginPage';
dayjs.extend(isoWeek);

export default function Dashboard() {
    const { user } = useContext(AuthContext);
    const { topics, members, skills, subgroups, assignments, getTopics, getAssignments, getSkills, getSubgroups, assignmentsCount, outreachEvents } = useContext(DataContext);
    const [hours, setHours] = useState(null);
    const [skillsCount, setSkillsCount] = useState({ Completed: 0, Assigned: 0 });
    const [displayFilter, setDisplayFilter] = useState({ assignments: true, skills: false });
    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

    const mapTopics = useMemo(() => topics.map((topicsData) => {
        let filteredAssignments = assignments.filter(assignmentsData => assignmentsData.assignmentTopicID === topicsData._id);
        if (filteredAssignments.length > 0 && filteredAssignments.find((assignment) => assignment.assigned.includes(user.displayName)) || user.admin) {
            return <TopicCard key={topicsData._id} id={topicsData._id} topics={topics} getTopics={getTopics} name={topicsData.topicName}
                members={members} getAssignments={getAssignments} assignments={filteredAssignments} />
        }
    }), [topics, user, members, assignments]);

    const mapSubgroups = useMemo(() => subgroups.map((subgroupData) => {
        let filteredSkills = skills.filter(skillsData => skillsData.skillSubgroupID === subgroupData._id);
        if (filteredSkills.length > 0 && filteredSkills.find((skill) => skill.assigned.includes(user.displayName)) || user.admin) {
            return <SubgroupCard key={subgroupData._id} id={subgroupData._id} subgroups={subgroups} getSubgroups={getSubgroups} name={subgroupData.subgroupName}
                members={members} getSkills={getSkills} skills={filteredSkills} />
        }
    }), [subgroups, user, members, skills]);

    const totalOutreachHours = useMemo(() => outreachEvents.filter(outreachEvent => outreachEvent.completed.includes(user.displayName))
        .reduce((accumulator, currentValue) => accumulator + currentValue.outreachTimeHours, 0), [outreachEvents]);

    useEffect(() => {
        fetch(`/transactions/getStudentHours`, {
            method: 'GET',
        }).then(response => {
            if (response.status === 200) {
            }
            return response.json();
        }).then(function (data) {
            (data) && setHours(data);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        let assignedSkills = skills.filter((skill) => skill.assigned.includes(user.displayName));
        let completedSkills = skills.filter((skill) => skill.completed.includes(user.displayName));

        let skillsCounter = { Completed: completedSkills.length, Assigned: assignedSkills.length };

        setSkillsCount(skillsCounter);
    }, [skills]);

    if (user === 'NoUser') {
        return <FailedLoginPage />
    }

    return (
        <Box>
            <Tabs isFitted index={0} size="lg" colorScheme="green" background={"gray.800"} >
                <Box background={"#3e3e3e"} overflow='auto'
                    position='sticky'
                    top='80px'
                    zIndex='998'
                    css={{
                        '&::-webkit-scrollbar': {
                            height: '8px',
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                            width: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                            borderRadius: '24px',
                        },
                    }}>
                    <TabList top={"5rem"} zIndex={998} position={"sticky"}>
                        <Tab>Dashboard</Tab>
                    </TabList>
                </Box>
                <TabPanels marginTop='12px' userSelect='none'>
                    <Container maxW="6xl">
                        <Stack direction={isMobile ? 'column' : 'row'} alignItems={'flex-start'}>
                            {isMobile ? <>
                                <Box width="100%">
                                    <VStack>
                                        <Card variant='outline' w={'100%'}>
                                            <CardHeader pb={1}>
                                                <HStack>
                                                    <IoMdTime size={20} />
                                                    <Heading size='sm'>Hours</Heading>
                                                    <Box flex='1' />
                                                    {hours?.season && <Text fontSize='sm' color='darkgray'>{hours?.season}</Text>}
                                                </HStack>
                                            </CardHeader>
                                            {(hours?.season || hours?.sumHours || hours?.avgHours || totalOutreachHours) ?
                                                <CardBody pt={1}>
                                                    <Flex fontSize='sm' alignItems="center">
                                                        <Box>Total Season Hours:</Box>
                                                        <Box flex="1" />
                                                        <Box fontWeight='bold' color="blue.300">
                                                            {hours?.sumHours ?? '0.0'}
                                                        </Box>
                                                    </Flex>
                                                    <Flex fontSize='sm' alignItems="center">
                                                        <Box>Average Team Hours:</Box>
                                                        <Box flex="1" />
                                                        <Box fontWeight='bold' color="blue.300">
                                                            {hours?.avgHours ?? '-'}
                                                        </Box>
                                                    </Flex>
                                                    <Divider  my={'0.125rem'} />
                                                    <Flex fontSize='sm' alignItems="center">
                                                        <Box>Total Outreach Hours:</Box>
                                                        <Box flex="1" />
                                                        <Box fontWeight='bold' color="blue.300">
                                                            {totalOutreachHours.toFixed(1)}
                                                        </Box>
                                                    </Flex>
                                                </CardBody>
                                                :
                                                <Center>
                                                    {hours?.error ?
                                                        <Text fontWeight='bold' as='span' mb={6}>{hours?.error}</Text> :
                                                        <Spinner mb={6} />}
                                                </Center>
                                            }
                                        </Card>
                                        <Card variant='outline' w={'100%'}>
                                            <SimpleGrid columns={2} spacing={6}>
                                                <Box>
                                                    <Button leftIcon={<HiOutlineClipboardList color="orange" size={20} />}
                                                        mt={2} ml={3} px={2} size="sm" colorScheme='gray' variant='ghost'
                                                        color={displayFilter.assignments ? "steelblue" : "auto"}
                                                        bg={displayFilter.assignments ? "rgba(255,255,255,0.1)" : "none"}
                                                        onClick={() => {
                                                            let filter = { assignments: true, skills: false };
                                                            setDisplayFilter(filter)
                                                        }}>
                                                        Assignments
                                                    </Button>
                                                    <CardBody pt={1} pr={0}>
                                                        <Flex fontSize='sm' alignItems="center" mb={'0.25rem'}>
                                                            <Box>Due soon:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={(assignmentsCount.ThisWeek + assignmentsCount.NextWeek) === 0 ? "lightgray" : "blue.300"}>
                                                                {assignmentsCount.ThisWeek + assignmentsCount.NextWeek}
                                                            </Box>
                                                        </Flex>
                                                        <Flex fontSize='sm' alignItems="center" mb={'0.25rem'}>
                                                            <Box>Due later:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={assignmentsCount.Later === 0 ? "lightgray" : "blue.300"}>
                                                                {assignmentsCount.Later}
                                                            </Box>
                                                        </Flex>
                                                        <Flex fontSize='sm' alignItems="center">
                                                            <Box>Past due:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={assignmentsCount.PastDue > 0 ? "red.300" : assignmentsCount.PastDue === 0 ? "lightgray" : "blue.300"}>
                                                                {assignmentsCount.PastDue}
                                                            </Box>
                                                        </Flex>
                                                    </CardBody>
                                                </Box>
                                                <Box>
                                                    <Button leftIcon={<GiUpgrade color="steelblue" size={20} />}
                                                        onClick={() => {
                                                            let filter = { assignments: false, skills: true };
                                                            setDisplayFilter(filter)
                                                        }}
                                                        mt={2} px={2} size="sm" colorScheme='gray' variant='ghost'
                                                        color={displayFilter.skills ? "steelblue" : "auto"}
                                                        bg={displayFilter.skills ? "rgba(255,255,255,0.1)" : "none"}>
                                                        Skills
                                                    </Button>
                                                    <CardBody pt={1} pl={0}>
                                                        <Flex fontSize='sm' alignItems="center" mb={'0.25rem'}>
                                                            <Box ml={2}>Signed Off:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={(skillsCount.Completed === skillsCount.Assigned) && skillsCount.Completed !== 0 ? "green.300" : "lightgray"}>
                                                                {skillsCount.Completed}
                                                            </Box>
                                                        </Flex>
                                                        <Flex fontSize='sm' alignItems="center" mb={'0.25rem'}>
                                                            <Box ml={2}>Required:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={skillsCount.Assigned === 0 ? "lightgray" : "blue.300"}>
                                                                {skillsCount.Assigned}
                                                            </Box>
                                                        </Flex>
                                                    </CardBody>
                                                </Box>
                                            </SimpleGrid>
                                        </Card>
                                    </VStack>
                                </Box> </> :
                                <Box width="320px">
                                    <VStack>
                                        <Card variant='outline' w={'100%'}>
                                            <CardHeader pb={1}>
                                                <HStack>
                                                    <IoMdTime size={20} />
                                                    <Heading size='sm'>Hours</Heading>
                                                    <Box flex='1' />
                                                    {hours?.season && <Text fontSize='sm' color='darkgray'>{hours?.season}</Text>}
                                                </HStack>
                                            </CardHeader>
                                            {(hours?.season || hours?.sumHours || hours?.avgHours || totalOutreachHours) ?
                                                <CardBody pt={1}>
                                                    <Flex fontSize='sm' alignItems="center">
                                                        <Box>Total Season Hours:</Box>
                                                        <Box flex="1" />
                                                        <Box fontWeight='bold' color="blue.300">
                                                            {hours?.sumHours ?? '0.0'}
                                                        </Box>
                                                    </Flex>
                                                    <Flex fontSize='sm' alignItems="center">
                                                        <Box>Average Team Hours:</Box>
                                                        <Box flex="1" />
                                                        <Box fontWeight='bold' color="blue.300">
                                                            {hours?.avgHours ?? '-'}
                                                        </Box>
                                                    </Flex>
                                                    <Divider my={'0.125rem'}/>
                                                    <Flex fontSize='sm' alignItems="center">
                                                        <Box>Total Outreach Hours:</Box>
                                                        <Box flex="1" />
                                                        <Box fontWeight='bold' color="blue.300">
                                                            {totalOutreachHours.toFixed(1)}
                                                        </Box>
                                                    </Flex>
                                                </CardBody>
                                                :
                                                <Center>
                                                    {hours?.error ?
                                                        <Text fontWeight='bold' as='span' mb={6}>{hours?.error}</Text> :
                                                        <Spinner mb={6} />}
                                                </Center>
                                            }
                                        </Card>
                                        <Card variant='outline' w={'100%'}>
                                            <SimpleGrid columns={2} spacing={6}>
                                                <Box>
                                                    <Button leftIcon={<HiOutlineClipboardList color="orange" size={20} />}
                                                        mt={2} ml={3} px={2} size="sm" colorScheme='gray' variant='ghost'
                                                        color={displayFilter.assignments ? "steelblue" : "auto"}
                                                        bg={displayFilter.assignments ? "rgba(255,255,255,0.1)" : "none"}
                                                        onClick={() => {
                                                            let filter = { assignments: true, skills: false };
                                                            setDisplayFilter(filter)
                                                        }}>
                                                        Assignments
                                                    </Button>
                                                    <CardBody pt={1} pr={0}>
                                                        <Flex fontSize='sm' alignItems="center" mb={'0.25rem'}>
                                                            <Box>Due soon:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={(assignmentsCount.ThisWeek + assignmentsCount.NextWeek) === 0 ? "lightgray" : "blue.300"}>
                                                                {assignmentsCount.ThisWeek + assignmentsCount.NextWeek}
                                                            </Box>
                                                        </Flex>
                                                        <Flex fontSize='sm' alignItems="center" mb={'0.25rem'}>
                                                            <Box>Due later:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={assignmentsCount.Later === 0 ? "lightgray" : "blue.300"}>
                                                                {assignmentsCount.Later}
                                                            </Box>
                                                        </Flex>
                                                        <Flex fontSize='sm' alignItems="center">
                                                            <Box>Past due:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={assignmentsCount.PastDue > 0 ? "red.300" : assignmentsCount.PastDue === 0 ? "lightgray" : "blue.300"}>
                                                                {assignmentsCount.PastDue}
                                                            </Box>
                                                        </Flex>
                                                    </CardBody>
                                                </Box>
                                                <Box>
                                                    <Button leftIcon={<GiUpgrade color="steelblue" size={20} />}
                                                        onClick={() => {
                                                            let filter = { assignments: false, skills: true };
                                                            setDisplayFilter(filter)
                                                        }}
                                                        mt={2} px={2} size="sm" colorScheme='gray' variant='ghost'
                                                        color={displayFilter.skills ? "steelblue" : "auto"}
                                                        bg={displayFilter.skills ? "rgba(255,255,255,0.1)" : "none"}>
                                                        Skills
                                                    </Button>
                                                    <CardBody pt={1} pl={0}>
                                                        <Flex fontSize='sm' alignItems="center" mb={'0.25rem'}>
                                                            <Box ml={2}>Signed Off:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={(skillsCount.Completed === skillsCount.Assigned) && skillsCount.Completed !== 0 ? "green.300" : "lightgray"}>
                                                                {skillsCount.Completed}
                                                            </Box>
                                                        </Flex>
                                                        <Flex fontSize='sm' alignItems="center" mb={'0.25rem'}>
                                                            <Box ml={2}>Required:</Box>
                                                            <Box flex="1" />
                                                            <Box fontWeight='bold' color={skillsCount.Assigned === 0 ? "lightgray" : "blue.300"}>
                                                                {skillsCount.Assigned}
                                                            </Box>
                                                        </Flex>
                                                    </CardBody>
                                                </Box>
                                            </SimpleGrid>
                                        </Card>
                                    </VStack>
                                </Box>}
                            <Box flex="1" w={isMobile && '100%'} >
                                <Container maxW="3xl" px={isMobile ? 0 : 4}>
                                    <VStack spacing={2} alignItems={'flex-start'}>
                                        {displayFilter.assignments && mapTopics}
                                        {displayFilter.skills && mapSubgroups}
                                    </VStack>
                                    {(skillsCount.Completed === 0 && skillsCount.Assigned === 0 && assignmentsCount.Completed === 0 && assignmentsCount.Assigned === 0 && !user.admin) &&
                                        <Card align='center' w="100%">
                                            <CardHeader p={4}>
                                                <Flex flex='1' gap='4' flexWrap="nowrap">
                                                    <Box>
                                                        <Heading size='sm'>There's nothing here</Heading>
                                                    </Box>
                                                </Flex>
                                            </CardHeader>
                                        </Card>}
                                </Container>
                            </Box>
                        </Stack>
                    </Container>
                </TabPanels >
            </Tabs >
        </Box >
    );
}