import dayjs from 'dayjs';
import React, { useEffect, createContext, useState } from 'react';

// @ts-ignore
const DataContext = createContext();

function DataProvider(props) {
    const { user } = props;

    const [topics, setTopics] = useState([]);
    const [subgroups, setSubgroups] = useState([]);
    const [members, setMembers] = useState([]);
    const [compliance, setCompliance] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [skills, setSkills] = useState([]);
    const [outreachEvents, setOutreachEvents] = useState([]);

    const [assignmentsCount, setAssignmentsCount] = useState({ NoDueDate: 0, ThisWeek: 0, NextWeek: 0, Later: 0, PastDue: 0 });

    const getCompliance = () => {
        fetch(`/assignmentsTransactions/getCompliance`, {
            method: 'GET',
        }).then(response =>
            response.json()
        ).then(data => {
            setCompliance(data);
        }).catch(err => {
            console.error(err);
        });
    }

    const getMembers = () => {
        fetch(`/transactions/getMembers`, {
            method: 'GET',
        }).then(response =>
            response.json()
        ).then(data => {
            setMembers(data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
        }).catch(err => {
            console.error(err);
        });
    }

    const getAssignments = () => {
        fetch(`/assignmentsTransactions/getAssignments`, {
            method: 'GET',
        }).then(response =>
            response.json()
        ).then(data => {
            let assignedAssignments = data.filter((assignment) => assignment.assigned.includes(user.displayName) &&
                !assignment.completed.includes(user.displayName) && !assignment.resource);
            let completedAssignments = data.filter((assignment) => assignment.completed.includes(user.displayName));

            let dueDates = { NoDueDate: 0, ThisWeek: 0, NextWeek: 0, Later: 0, PastDue: 0, Completed: completedAssignments.length, Assigned: assignedAssignments.length };

            assignedAssignments.forEach((assigned) => {
                const dueDate = dayjs(assigned.dueDate);
                const today = dayjs();
                const dateDiff = dueDate.diff(today, 'day', true);
                const isPastDue = (today > dueDate);
                const isThisWeek = (dateDiff > 0 && dateDiff < 7);
                const isNextWeek = (dateDiff > 7 && dateDiff < 14);
                const isLaterWeek = (dateDiff > 14);

                if (!assigned.dueDate) {
                    dueDates.NoDueDate += 1;
                } else if (isPastDue) {
                    dueDates.PastDue += 1;
                } else if (isThisWeek) {
                    dueDates.ThisWeek += 1;
                } else if (isNextWeek) {
                    dueDates.NextWeek += 1;
                } else if (isLaterWeek) {
                    dueDates.Later += 1;
                }
            });
            setAssignmentsCount(dueDates);
            setAssignments(data);
        }).catch(err => {
            console.error(err);
        });
    }

    const getSkills = () => {
        fetch(`/assignmentsTransactions/getSkills`, {
            method: 'GET',
        }).then(response =>
            response.json()
        ).then(data => {
            setSkills(data);
        }).catch(err => {
            console.error(err);
        });
    }

    const getTopics = () => {
        fetch(`/assignmentsTransactions/getTopics`, {
            method: 'GET',
        }).then(response =>
            response.json()
        ).then(data => {
            setTopics(data);
        }).catch(err => {
            console.error(err);
        });
    }

    const getSubgroups = () => {
        fetch(`/assignmentsTransactions/getSubgroups`, {
            method: 'GET',
        }).then(response =>
            response.json()
        ).then(data => {
            setSubgroups(data);
        }).catch(err => {
            console.error(err);
        });
    }

    const getOutreachEvents = () => {
        fetch(`/assignmentsTransactions/getOutreachEvents`, {
            method: 'GET',
        }).then(response =>
            response.json()
        ).then(data => {
            setOutreachEvents(data);
        }).catch(err => {
            console.error(err);
        });
    }

    useEffect(() => {
        getMembers();
        getSkills();
        getTopics();
        getAssignments();
        getSubgroups();
        getCompliance();
        getOutreachEvents();
    }, []);

    return <DataContext.Provider value={{
        members, skills, topics, assignments, subgroups, compliance, assignmentsCount, outreachEvents,
        getMembers, getSkills, getTopics, getAssignments, getSubgroups, getCompliance, getOutreachEvents,
        setTopics, setAssignments, setSkills
    }} {...props} />;

}

export { DataContext, DataProvider };