// theme.js

// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'
import '@fontsource/open-sans';

// 2. Add your color mode config
const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
}

const fonts = {
    heading: `Open Sans,Arial,sans-serif`,
    body: `Open Sans,Arial,sans-serif`,
}


const style = {
    styles: {
        global: {
            'html, body, h2, span, button, a, p': {
                letterSpacing: 'wide'
            },
            '.picker-dialog': {
                zIndex: '20001 !important'
            },
            '.picker-dialog-bg': {
                zIndex: '20000 !important'
            },
        },
    }
}
// 3. extend the theme
const theme = extendTheme(style, { config, fonts })

export default theme