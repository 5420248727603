import { HStack, Menu, MenuButton, IconButton, MenuList, MenuItem, Avatar, Icon, Box, Text, Divider, AccordionItem, AccordionButton, AccordionPanel, Stat, StatLabel, StatNumber, useDisclosure, VStack, Flex, Spacer, Badge } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GiUpgrade } from "react-icons/gi";
import SignOffModal from "./SignOffModal";
import AddSkillModal from "./AddSkillModal";
import LinkCard from "./LinkCard";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';

export default function SkillCard(props) {
    const { isOpen: isOpenSignOff, onOpen: onOpenSignOff, onClose: onCloseSignOff } = useDisclosure();
    const { isOpen: isOpenEditSkill, onOpen: onOpenEditSkill, onClose: onCloseEditSkill } = useDisclosure();

    const { _id, skillName, skillSubgroupID, skillInstructions, skillLinks, dueDate, assigned, completed, createdAt, dateLastEdited } = props.skillData;
    const { members, getSubgroups, getSkills, editSubgroup, setEditSubgroup, subgroups, subgroupName, setAlertDialogFunction, setAlertDialogTitle, onOpenAlertDialog, user } = props;

    const editSkill = useRef({ id: _id, skillName: skillName, skillInstructions: skillInstructions, skillLinks: skillLinks, dueDate: dueDate, skillSubgroupID: skillSubgroupID, assigned: assigned });

    const [isCompleted, setIsCompleted] = useState(false);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: _id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const deleteOnClickHandler = () => {
        setAlertDialogFunction(() => () => {
            fetch(`/assignmentsTransactions/deleteSkill`, {
                method: 'POST',
                // We convert the React state to JSON and send it as the POST body
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    _id: _id
                })
            }).then(response => {
                if (response.status === 200) {
                    getSkills();
                }
                return response.text();
            }).then(function (data) {
                (data) && console.log(data);
            }).catch(err => {
                console.log(err);
            });
        });

        setAlertDialogTitle('Delete Skill');
        onOpenAlertDialog();
    }

    useEffect(() => {
        editSkill.current = { id: _id, skillName: skillName, skillInstructions: skillInstructions, skillLinks: skillLinks, dueDate: dueDate, skillSubgroupID: skillSubgroupID, assigned: assigned };
    }, [props.skillData]);

    useEffect(() => {
        setIsCompleted(completed.includes(user.displayName));
    }, [completed]);

    return (
        <>
            <SignOffModal isOpen={isOpenSignOff} onClose={onCloseSignOff} data={props.skillData} members={members} getSkills={getSkills} />
            {(editSubgroup && setEditSubgroup) && (
                <><AddSkillModal isOpen={isOpenEditSkill} onClose={onCloseEditSkill} subgroupName={subgroupName}
                    subgroups={subgroups} members={members} getSubgroups={getSubgroups} getSkills={getSkills} editSubgroup={editSubgroup} setEditSubgroup={setEditSubgroup}
                    editSkill={editSkill.current} />
                </>)
            }
            <AccordionItem ref={setNodeRef} style={style} {...attributes}
                borderBottom='none'
                _hover={{
                    bg: "rgba(255,255,255,0.04)"
                }}
            >
                {({ isExpanded }) => {
                    return (
                        <>
                            <Box backgroundColor={isExpanded ? "rgba(255,255,255,0.04)" : 'none'}>
                                <HStack spacing={4} pr="1.25rem"  {...listeners}>
                                    <AccordionButton minW={0} pr={0}>
                                        <Avatar background={!isCompleted ? "blue.100" : "blue.500"} w="2.2rem" h="2.2rem" mr={4} icon={<Icon as={GiUpgrade} w={6} h={6} color='white' />} />
                                        <Text fontSize={{ base: "sm", md: "md" }} minW={0} fontWeight='semibold' color="gray.200" isTruncated={true}>{skillName}</Text>
                                        <Box flex="1" />
                                        {!(editSubgroup && setEditSubgroup) &&
                                            <Badge variant='subtle' colorScheme={isCompleted ? 'green' : 'gray'}>
                                                {isCompleted && 'Signed Off'}
                                            </Badge>}
                                    </AccordionButton>
                                    {(editSubgroup && setEditSubgroup) &&
                                        <Menu>
                                            <MenuButton as={IconButton}
                                                aria-label='menu' bg="transparent" color="rgba(255,255,255,0.5)" size="lg" fontSize='20px' isRound icon={<BsThreeDotsVertical />}
                                                _hover={{
                                                    color: "white",
                                                    bg: "rgba(255,255,255,0.16)"
                                                }}
                                            />
                                            <MenuList minWidth={'8rem'} >
                                                <MenuItem pb={3} onClick={onOpenSignOff}>
                                                    Sign Off
                                                </MenuItem>
                                                <Divider />
                                                <MenuItem py={3} onClick={onOpenEditSkill}>
                                                    Edit Skill
                                                </MenuItem>
                                                <MenuItem py={3} onClick={deleteOnClickHandler}>
                                                    Delete Skill
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    }
                                </HStack>
                                {isExpanded && <Divider color={'rgba(255,255,255,0.16)'} />}
                                <AccordionPanel pb={skillLinks.length > 0 ? 2 : 0}>
                                    <Flex mb={2}>
                                        <VStack spacing={0} alignItems="flex-start">
                                            <Text fontSize="xs" color="darkgray">{dateLastEdited ? ` Edited ${new Date(dateLastEdited).toLocaleString('en-US', { month: 'short', day: '2-digit' })}`
                                                : `Posted ${new Date(createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit' })}`}
                                            </Text>
                                            <Text my={1} mr={1} fontSize="sm" whiteSpace={'pre-line'}>{skillInstructions}</Text>
                                        </VStack>
                                        {(editSubgroup && setEditSubgroup) ? <>
                                            <Spacer />
                                            <HStack onClick={onOpenSignOff} _hover={{ cursor: 'pointer' }} alignItems="flex-start">
                                                <Stat borderLeft="1px solid rgba(255,255,255,0.16)">
                                                    <Box mx={{ base: 2, md: 4 }}>
                                                        <StatNumber fontSize={{ base: 'md', md: '3xl' }}>{completed.length}</StatNumber>
                                                        <StatLabel>Completed</StatLabel>
                                                    </Box>
                                                </Stat>
                                                <Stat borderLeft="1px solid rgba(255,255,255,0.16)">
                                                    <Box mx={{ base: 2, md: 4 }}>
                                                        <StatNumber fontSize={{ base: 'md', md: '3xl' }}>{assigned.length}</StatNumber>
                                                        <StatLabel>Assigned</StatLabel>
                                                    </Box>
                                                </Stat>
                                            </HStack> </> : <><Spacer />
                                            {!(editSubgroup && setEditSubgroup) &&
                                                <Text px={2} fontSize="xs" fontWeight="bold" color={'white'}>
                                                    Assigned
                                                </Text>}</>}
                                    </Flex>

                                    {skillLinks.map((link, index) => {
                                        return (
                                            <Box key={link} mb={index !== skillLinks.length - 1 ? 2 : 0}>
                                                <LinkCard url={link} />
                                            </Box>)
                                    })}
                                </AccordionPanel>
                            </Box>
                        </>)
                }
                }
            </AccordionItem >
        </>
    )
}
