import { HStack, Avatar, Box, Text, Divider, useDisclosure, Stack, useBreakpointValue, Badge, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, SimpleGrid, Stat, StatHelpText, StatLabel, StatNumber, Button, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Tag, Flex, Tooltip, UnorderedList, ListItem } from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { FaExclamation } from "react-icons/fa";
import { CheckIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import LinkCard from "./LinkCard";
import AssignmentOverviews from "./AssignmentsOverviews";
import SkillOverviews from "./SkillsOverviews";

export default function ComplianceCard(props) {

    const { topics, subgroups } = props;
    const { profile, assignments, skills, outreachEvents, academicProbation } = props.complianceItem;
    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

    const outOfCompliance = useMemo(() => assignments.some(e => e.pastDue), [assignments]);
    const totalOutreachHours = useMemo(() => outreachEvents.reduce((accumulator, currentValue) => accumulator + currentValue.outreachTimeHours, 0), [outreachEvents]);

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        assignments.sort((a, b) => {
            if (!a.completed && !b.completed) {
                if (a.pastDue && !b.pastDue) {
                    return -1;
                } else if (!a.pastDue && b.pastDue) {
                    return 1;
                }
            }

            if (!a.completed && b.completed) {
                return -1;
            } else if (a.completed && !b.completed) {
                return 1;
            } else {
                return 0;
            }
        });
    }, [assignments]);

    return (
        <>
            <Modal onClose={onClose} size={'4xl'} isOpen={isOpen} blockScrollOnMount={false}>
                <ModalOverlay />
                <ModalContent userSelect={'none'}>
                    <ModalHeader>
                        <HStack>
                            <Text>{profile.name}</Text>
                            <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
                                {academicProbation && <Badge fontSize={isMobile ? '2xs' : 'xs'} variant='solid' colorScheme={'red'}>
                                    Academic Probation
                                </Badge>}
                                {outOfCompliance && <Badge fontSize={isMobile ? '2xs' : 'xs'} variant='solid' colorScheme={'red'}>
                                    Out of Compliance
                                </Badge>}
                                {(!academicProbation && !outOfCompliance) && <Badge fontSize={isMobile ? '2xs' : 'xs'} variant='solid' colorScheme={'green'}>
                                    Good Standing
                                </Badge>}
                            </Stack>
                        </HStack>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Tabs>
                            <TabList>
                                <Tab>Assignments</Tab>
                                <Tab>Skill Sign-Offs</Tab>
                                <Tab>Hours</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    {(assignments.length > 0 && topics.length > 0) ?
                                        topics.map(topic => {
                                            let filteredAssignments = assignments.filter(assignment => assignment.assignmentTopicID === topic._id);
                                            if (filteredAssignments.length > 0) {
                                                return <Box key={topic._id}>
                                                    <Badge isTruncated fontWeight={'bold'} >
                                                        {topic.topicName}
                                                    </Badge>
                                                    {filteredAssignments.map((assignment, i) => {
                                                        return (
                                                            <div key={assignment._id}>
                                                                <AssignmentOverviews assignment={assignment} />
                                                                {(assignments.length - 1 !== i) && <Divider />}
                                                            </div>
                                                        )
                                                    })}
                                                </Box>
                                            }
                                        }) : <Box py={3}>
                                            <Text isTruncated textAlign={'center'} fontWeight={'bold'}>
                                                There's nothing here
                                            </Text>
                                        </Box>}
                                </TabPanel >
                                <TabPanel>
                                    {(skills.length > 0 && subgroups.length > 0) ?
                                        subgroups.map(subgroup => {
                                            let filteredSkills = skills.filter(skill => skill.skillSubgroupID === subgroup._id);
                                            if (filteredSkills.length > 0) {
                                                return <Box key={subgroup._id}>
                                                    <Badge isTruncated fontWeight={'bold'} >
                                                        {subgroup.subgroupName}
                                                    </Badge>
                                                    {filteredSkills.map((skill, i) => {
                                                        return (<div key={skill._id}>
                                                            <SkillOverviews skill={skill} />
                                                            {(skills.length - 1 !== i) && <Divider />}
                                                        </div>
                                                        )
                                                    })}
                                                </Box>
                                            }
                                        }) : <Box py={3}>
                                            <Text isTruncated textAlign={'center'} fontWeight={'bold'}>
                                                There's nothing here
                                            </Text>
                                        </Box>}
                                </TabPanel>
                                <TabPanel p={6}>
                                    <Box>
                                        <HStack alignItems={'flex-start'}>
                                            <Stat>
                                                <StatLabel>Total Season Hours</StatLabel>
                                                <StatNumber>{profile.TotalHours}</StatNumber>
                                                <StatHelpText>{profile.Season}</StatHelpText>
                                            </Stat>
                                            <Stat>
                                                <StatLabel>Total Outreach Hours</StatLabel>
                                                <StatNumber>{totalOutreachHours}</StatNumber>
                                            </Stat>
                                        </HStack>
                                    </Box>
                                    <Divider />
                                    <Text isTruncated fontWeight={'bold'} mt={2}>
                                        Outreach Events
                                    </Text>
                                    {(outreachEvents.length > 0) ?
                                        outreachEvents.map((outreachEvent, i) => {
                                            return <div key={outreachEvent._id}>
                                                <Box py={3}>
                                                    <SimpleGrid minChildWidth='100px'>
                                                        <Text isTruncated fontWeight={'bold'}>
                                                            {outreachEvent.outreachEventName}
                                                        </Text>
                                                        <Text fontSize={'xs'} alignContent={'center'} justifySelf={'flex-end'} color='darkgray'>
                                                            {outreachEvent.outreachEventDateTime && dayjs(outreachEvent.outreachEventDateTime).format('MMM D YYYY')}
                                                        </Text>
                                                        <Flex alignContent={'center'} justifySelf={'flex-end'} alignItems={'center'}>
                                                            <Badge variant='solid' colorScheme={'green'} h="fit-content">
                                                                Attended
                                                            </Badge>
                                                        </Flex>
                                                    </SimpleGrid>
                                                </Box>
                                                {(outreachEvents.length - 1 !== i) && <Divider />}
                                            </div>
                                        }) : <Box py={3}>
                                            <Text isTruncated textAlign={'center'} fontWeight={'bold'}>
                                                There's nothing here
                                            </Text>
                                        </Box>}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </ModalBody>
                </ModalContent>
            </Modal >
            <Box
                borderTop='1px solid rgba(255,255,255,0.16)'
                _hover={{
                    bg: "rgba(255,255,255,0.04)",
                    cursor: "pointer"
                }}
                onClick={onOpen}
            >
                <HStack spacing={4} px={6} py={2}>
                    <Avatar name={profile.name.split(' ')[0]} src={profile.iconImage} w="2.2rem" h="2.2rem" />
                    <Text fontSize={{ base: "sm", md: "md" }} minW={0} fontWeight='semibold' color="gray.200" isTruncated>{profile.name}</Text>
                    <Box flex="1" />
                    <Text fontSize={isMobile ? "2xs" : "xs"} color="gray.400" minW={'70px'}>{profile.TotalHours} hours</Text>
                    {(outOfCompliance || academicProbation) ?
                        <Tooltip label={<UnorderedList>
                            {outOfCompliance && <ListItem>Out of Compliance</ListItem>}
                            {academicProbation && <ListItem>Academic Probation</ListItem>}
                        </UnorderedList>}>
                            <Avatar bg='red.500' icon={<FaExclamation fontSize='0.9rem' />} size="xs" />
                        </Tooltip>
                        :
                        <Avatar bg='green.500' icon={<CheckIcon fontSize='0.9rem' />} size="xs" />
                    }
                </HStack>
            </Box >
        </>
    )
}
