import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Button } from "@chakra-ui/react";
import { customInputStyle } from "util/constants";

export default function AddTopicModal(props) {

    const { isOpen, onClose, getTopics, setAssignToTopic, editTopic } = props;

    const topicNameField = useRef(null);
    const [topicNameValue, setTopicNameValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleTopicNameChange = (event) => setTopicNameValue(event.target.value);

    useEffect(() => {
        editTopic.name && setTopicNameValue(editTopic.name);
    }, [editTopic]);

    const resetModal = () => {
        topicNameField.current.value = '';
        setTopicNameValue('');
    }

    const createTopicOnClickHandler = () => {
        setIsSubmitting(true);
        fetch(`/assignmentsTransactions/createTopic`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                topicName: topicNameField.current.value
            })
        }).then(response => {
            if (response.status === 200) {
                onClose();
                setTopicNameValue('');
                getTopics();
                return response.json();
            }
            return response.text();
        }).then(response => {
            if (setAssignToTopic && response._id)
                setAssignToTopic({ _id: response._id, topicName: response.topicName });
        }).catch(err => {
            console.log(err);
        }).finally(() =>
            setIsSubmitting(false));
    }

    const editTopicOnClickHandler = () => {
        setIsSubmitting(true);
        fetch(`/assignmentsTransactions/editTopic`, {
            method: 'POST',
            // We convert the React state to JSON and send it as the POST body
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                topicName: topicNameField.current.value,
                id: editTopic.id
            })
        }).then(response => {
            if (response.status === 200) {
                onClose();
                setTopicNameValue('');
                getTopics();
                return response.json();
            }
            return response.text();
        }).then(response => {
            if (setAssignToTopic && response._id)
                setAssignToTopic({ _id: response._id, topicName: response.topicName });
        }).catch(err => {
            console.log(err);
        }).finally(() =>
            setIsSubmitting(false));
    }

    return (
        <>
            <Modal
                initialFocusRef={topicNameField}
                isOpen={isOpen}
                onClose={() => { onClose(); resetModal(); }}
                size="xs"
                isCentered
            >
                <ModalOverlay />
                <ModalContent userSelect="none">
                    <ModalHeader pb={0}>{editTopic.id !== null ? 'Rename' : 'Add'} topic</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={2}>
                        <FormControl>
                            <Input ref={topicNameField} sx={customInputStyle} autoComplete='off' defaultValue={editTopic.name} size="lg" placeholder='Topic'
                                onChange={handleTopicNameChange}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && topicNameValue.length !== 0 && !isSubmitting) {
                                        (editTopic.id !== null ? editTopicOnClickHandler() : createTopicOnClickHandler())
                                    }
                                }} />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' ml={3} onClick={editTopic.id !== null ? editTopicOnClickHandler : createTopicOnClickHandler}
                            isDisabled={topicNameValue.length === 0 || isSubmitting}>
                            {editTopic.id !== null ? 'Rename' : 'Add'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
