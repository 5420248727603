import { HStack, Menu, MenuButton, IconButton, MenuList, MenuItem, Avatar, Icon, Box, Text, Divider, AccordionItem, AccordionButton, AccordionPanel, Stat, StatLabel, StatNumber, useDisclosure, VStack, Flex, Spacer, useBreakpointValue, Badge, Button } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import SignOffModal from "./SignOffModal";
import LinkCard from "./LinkCard";
import AddResourceModal from "./AddResourceModal";
import GDriveCard from "./GDriveCard";
import AddOutreachModal from "./AddOutreachModal";
import { IoMdCalendar } from "react-icons/io";
import dayjs from "dayjs";

export default function OutreachEventCard(props) {
    const { isOpen: isOpenEditOutreachEvent, onOpen: onOpenEditOutreachEvent, onClose: onCloseEditOutreachEvent } = useDisclosure();

    const { _id, outreachEventName, outreachEventInstructions,
        outreachEventLinks, outreachEventDateTime, outreachTimeHours,
        outreachEventAddress, outreachEventCoords, completed, createdAt, dateLastEdited } = props.outreachEventData;
    const { members, getTopics, getOutreachEvents, topics, topicName, setAlertDialogFunction, setAlertDialogTitle, onOpenAlertDialog, user } = props;

    const editOutreachEvent = useRef({
        id: _id, outreachEventName: outreachEventName, outreachEventInstructions: outreachEventInstructions,
        outreachEventLinks: outreachEventLinks, outreachEventDateTime: outreachEventDateTime, outreachTimeHours: outreachTimeHours,
        outreachEventAddress: outreachEventAddress, outreachEventCoords: outreachEventCoords, completed: completed
    });
    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

    const [isCompleted, setIsCompleted] = useState(false);

    const deleteOnClickHandler = () => {
        setAlertDialogFunction(() => () => {
            fetch(`/assignmentsTransactions/deleteOutreachEvent`, {
                method: 'POST',
                // We convert the React state to JSON and send it as the POST body
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    _id: _id
                })
            }).then(response => {
                if (response.status === 200) {
                    getOutreachEvents();
                }
                return response.text();
            }).then(function (data) {
                (data) && console.log(data);
            }).catch(err => {
                console.log(err);
            });
        });

        setAlertDialogTitle('Delete Outreach Event');
        onOpenAlertDialog();
    }

    useEffect(() => {
        editOutreachEvent.current = {
            id: _id, outreachEventName: outreachEventName, outreachEventInstructions: outreachEventInstructions,
            outreachEventLinks: outreachEventLinks, outreachEventDateTime: outreachEventDateTime, outreachTimeHours: outreachTimeHours,
            outreachEventAddress: outreachEventAddress, outreachEventCoords: outreachEventCoords, completed: completed
        };
    }, [props.outreachEventData]);

    useEffect(() => {
        setIsCompleted(completed.includes(user.displayName));
    }, [completed]);

    return (
        <>
            {(editOutreachEvent) && (
                <><AddOutreachModal isOpen={isOpenEditOutreachEvent} onClose={onCloseEditOutreachEvent} topicName={topicName} token={user.accessToken}
                    setAlertDialogFunction={setAlertDialogFunction}
                    topics={topics} members={members} getTopics={getTopics} getOutreachEvents={getOutreachEvents} editOutreachEvent={editOutreachEvent.current} />
                </>)
            }
            <AccordionItem
                borderBottom='none'
                _hover={{
                    bg: "rgba(255,255,255,0.04)"
                }}
            >
                {({ isExpanded }) => {
                    return (
                        <>
                            <Box backgroundColor={isExpanded ? "rgba(255,255,255,0.04)" : 'none'}>
                                <HStack spacing={4}>
                                    <AccordionButton minW={0} pr={0}>
                                        <Avatar background={isCompleted ? "green.300" : "green.500"} w="2.2rem" h="2.2rem" mr={4} icon={<Icon as={IoMdCalendar} w={6} h={6} color='white' />} />
                                        <Text fontSize={{ base: "sm", md: "md" }} minW={0} fontWeight='semibold' color="gray.200" isTruncated>{outreachEventName}</Text>
                                        <Box flex="1" />
                                        <Text px={2} fontSize={isMobile ? "2xs" : "xs"} color="gray.400" minW={'70px'}>{dayjs(outreachEventDateTime).format('MMM D YYYY')} {dayjs(outreachEventDateTime).format('h:mm a')} - {dayjs(outreachEventDateTime).add(outreachTimeHours, 'hours').format('h:mm a')}</Text>
                                    </AccordionButton>
                                    {(editOutreachEvent) &&
                                        <Menu>
                                            <MenuButton as={IconButton}
                                                aria-label='menu' bg="transparent" color="rgba(255,255,255,0.5)" size="lg" fontSize='20px' isRound icon={<BsThreeDotsVertical />}
                                                _hover={{
                                                    color: "white",
                                                    bg: "rgba(255,255,255,0.16)"
                                                }}
                                            />
                                            <MenuList minWidth={'8rem'} >
                                                <MenuItem py={3} onClick={onOpenEditOutreachEvent}>
                                                    Edit Outreach Event
                                                </MenuItem>
                                                <MenuItem py={3} onClick={() => { navigator.clipboard.writeText(`https://hub.robotigers1796.com/outreach/${_id}`) }}>
                                                    Copy Link
                                                </MenuItem>
                                                <MenuItem py={3} onClick={deleteOnClickHandler}>
                                                    Delete
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    }
                                </HStack>
                                {isExpanded && <Divider color={'rgba(255,255,255,0.16)'} />}
                                <AccordionPanel pb={outreachEventLinks.length > 0 ? 2 : 0}>
                                    <Flex mb={2}>
                                        <VStack spacing={0} alignItems="flex-start">
                                            {/* <Text fontSize="xs" color="darkgray">
                                                {dateLastEdited ? ` Edited ${new Date(dateLastEdited).toLocaleString('en-US', { month: 'short', day: '2-digit' })}`
                                                    : `Posted ${new Date(createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit' })}`}
                                            </Text> */}
                                            <Text fontSize="xs" color="darkgray">
                                                {outreachEventAddress}
                                            </Text>
                                            <Text my={1} mr={1} fontSize="sm" whiteSpace={'pre-line'}>{outreachEventInstructions}</Text>
                                        </VStack>
                                        {(editOutreachEvent) && <>
                                            <Spacer />
                                            <Box mx={{ base: 2, md: 4 }}>
                                                <Stat>
                                                    <StatNumber fontSize={{ base: 'md', md: '3xl' }}>{completed.length}</StatNumber>
                                                    <StatLabel>Attended</StatLabel>
                                                </Stat>
                                            </Box>
                                        </>
                                        }
                                    </Flex>
                                    {outreachEventLinks.map((link, index) => {
                                        return (
                                            <Box key={link} mb={(index !== outreachEventLinks.length - 1) ? 2 : 0}>
                                                <LinkCard url={link} />
                                            </Box>)
                                    })}
                                </AccordionPanel>
                            </Box>
                        </>)
                }}
            </AccordionItem >
        </>
    )
}
