import React, { useRef } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Box, Avatar, Checkbox, Flex, Heading, Text, Divider, HStack } from "@chakra-ui/react";

export default function AssignToModal(props) {

    const { isOpen, onClose, members, checkedItems, setCheckedItems, altName, handleOnClick } = props;

    const topicNameField = useRef(null);

    const handleCheckAll = (e) => {
        setCheckedItems(e.target.checked ? members.map(data => { return data.name }) : []);
    };

    const handleCheckItem = (item) => {
        let checkedItemsCopy = checkedItems;
        if (checkedItems.includes(item)) {
            checkedItemsCopy = checkedItemsCopy.filter((i) => i !== item);
            setCheckedItems(checkedItemsCopy);
        } else {
            checkedItemsCopy = [...checkedItemsCopy, item];
            setCheckedItems(checkedItemsCopy);
        }
    };

    return (
        <>
            <Modal
                initialFocusRef={topicNameField}
                isOpen={isOpen}
                onClose={onClose}
                size="2xl"
                isCentered
            >
                <ModalOverlay />
                <ModalContent userSelect="none">
                    <ModalHeader>{altName ?? 'Assign to'}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pl={0} pr={0}>
                        <Flex m={2} ml={8} mb={4} flex='1' gap='4' flexWrap="nowrap">
                            <Checkbox size="lg" isChecked={checkedItems?.length === members?.length} onChange={handleCheckAll}>
                                <Heading size='sm' ml={2} alignContent={'center'}>Select all</Heading>
                            </Checkbox>
                        </Flex>
                        <Divider />
                        <Box
                            height={{ base: '200px', md: '300px' }}
                            overflowY="auto"
                            sx={{
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: 'gray.300',
                                    borderRadius: 'md',
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: 'transparent',
                                },
                            }}
                        >
                            {members?.map((data) =>
                                <Flex key={data.name} _hover={{
                                    bg: "rgba(255,255,255,0.04)"
                                }}>
                                    <Flex m={2} ml={8} flex='1' gap='4' flexWrap="nowrap">
                                        <Checkbox size="lg" value={data.name} width="fill-available"
                                            isChecked={checkedItems && checkedItems.includes(data.name)} onChange={() => handleCheckItem(data.name)}>
                                            <HStack ml={2}>
                                                <Avatar name={data.name.split(' ')[0]} src={data.iconImage} />
                                                <Heading size='sm' alignContent={'center'}>{data.name}</Heading>
                                            </HStack>
                                        </Checkbox>
                                    </Flex>
                                </Flex>
                            )}
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Text fontWeight={'light'} color="darkgray">{checkedItems?.length} selected</Text>
                        <Button colorScheme='blue' ml={3} onClick={handleOnClick ?? onClose} isDisabled={altName === 'Update to Academic Probation' ? false : !checkedItems?.length}>
                            Done
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}