import { Card, CardHeader, HStack, Heading, Menu, MenuButton, IconButton, MenuList, MenuItem, Divider, CardBody, Avatar, Icon, Box, Accordion } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import AssignmentCard from "./AssignmentCard";
import { useContext } from "react";
import { AuthContext } from "context/auth";
import { useLocation } from 'react-router-dom';
import { DndContext, closestCenter, useSensor, useSensors, TouchSensor, MouseSensor, DragOverlay } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { Item } from "./Item";

export default function TopicCard(props) {
    const location = useLocation();
    const { user } = useContext(AuthContext);
    let { id, name, topics, assignments, members, onOpenAlertDialog, setAlertDialogTitle, setAlertDialogFunction, getTopics, setEditTopic, onOpenAddTopic, getAssignments, editTopic, setAssignments } = props;
    const [activeId, setActiveId] = useState(null);
    const itemIds = useMemo(() => assignments.map((assignment) => assignment._id), [assignments]);
    const mapAssignents = useMemo(() => assignments.map(assignmentData => {
        if (assignmentData.assigned.includes(user.displayName) || user.admin) {
            return <AssignmentCard key={assignmentData._id} user={user} assignmentData={assignmentData} topicName={name} members={members} topics={topics} getTopics={getTopics} getAssignments={getAssignments}
                editTopic={editTopic} setEditTopic={setEditTopic}
                setAlertDialogFunction={setAlertDialogFunction} setAlertDialogTitle={setAlertDialogTitle} onOpenAlertDialog={onOpenAlertDialog} />
        }
    }
    ), [assignments]);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 4
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 8
            },
        })
    );

    const deleteOnClickHandler = () => {
        setAlertDialogFunction(() => () => {
            fetch(`/assignmentsTransactions/deleteTopic`, {
                method: 'POST',
                // We convert the React state to JSON and send it as the POST body
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    _id: id
                })
            }).then(response => {
                if (response.status === 200) {
                    getTopics();
                }
                return response.text();
            }).then(function (data) {
                (data) && console.log(data);
            }).catch(err => {
                console.log(err);
            });
        });

        setAlertDialogTitle('Delete Topic');
        onOpenAlertDialog();
    }

    return (
        <Card width="100%" mb={2} align='left' variant="filled" bg="transparent" ref={location.pathname === '/assign' ? setNodeRef : null} style={style} {...attributes}
            _hover={{
                outline: "1px solid rgba(255,255,255, 0.16)"
            }}
        >
            <CardHeader py={3} {...listeners}>
                <HStack>
                    <Heading size='lg' _hover={{
                        textDecoration: 'underline',
                        color: '#3182ce'
                    }}> {name}</Heading>
                    <Box flex="1" />
                    {(setEditTopic && onOpenAddTopic) &&
                        <Menu>
                            <MenuButton as={IconButton}
                                aria-label='menu' bg="transparent" size="lg" fontSize='22px' isRound icon={<BsThreeDotsVertical />}
                            />
                            <MenuList minWidth={'8rem'} >
                                <MenuItem px={3} onClick={() => { setEditTopic({ id: id, name: name }); onOpenAddTopic(); }}>
                                    Rename Topic
                                </MenuItem >
                                <MenuItem px={3} onClick={deleteOnClickHandler}>
                                    Delete Topic
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    }
                </HStack>
            </CardHeader>
            <Accordion allowToggle>
                {location.pathname === '/assign' ?
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    >
                        <SortableContext
                            items={itemIds}
                            strategy={verticalListSortingStrategy}
                        >
                            {mapAssignents}
                        </SortableContext>
                        <DragOverlay>
                            {activeId ? <Item
                                // @ts-ignore
                                id={activeId} assignments={assignments} /> : null}
                        </DragOverlay>
                    </DndContext> :
                    mapAssignents
                }
            </Accordion>
        </Card>
    )
    function handleDragStart(event) {
        const { active } = event;

        setActiveId(active.id);
    }
    function handleDragEnd(event) {
        const { active, over } = event;

        if (over && active.id !== over.id) {
            setAssignments((items) => {
                const oldIndex = items.findIndex((item) => item._id === active.id);
                const newIndex = items.findIndex((item) => item._id === over.id);
                let movedArray = arrayMove(items, oldIndex, newIndex);

                items[oldIndex].assignmentIndex = movedArray.findIndex((item) => item._id === active.id);
                items[newIndex].assignmentIndex = movedArray.findIndex((item) => item._id === over.id);

                fetch(`/assignmentsTransactions/sortAssignments`, {
                    method: 'POST',
                    // We convert the React state to JSON and send it as the POST body
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        items: movedArray
                    })
                })
                    .catch(err => {
                        console.log(err);
                    });
                return movedArray;
            });
        }
    }
}