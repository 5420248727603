import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, Center, Tab, TabList, TabPanels, Tabs, MenuButton, Menu, MenuItem, MenuList, Container, Card, CardHeader, HStack, Heading } from '@chakra-ui/react';
import { AuthContext } from 'context/auth';
import { DataContext } from 'context/data';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import ComplianceCard from 'components/ComplianceCard';
import dayjs from 'dayjs';
import FailedLoginPage from './FailedLoginPage';

export default function Overview() {
    const { user } = useContext(AuthContext);
    const { topics, members, assignments, subgroups, skills, compliance, outreachEvents } = useContext(DataContext);
    const [sortFunction, setSortFunction] = useState({ sortType: 'compliance', sortOrder: 'desc', function: (a, b) => complianceSort(a, b, 'desc') });

    const assignmentCompliance = useMemo(() => {
        return members.map(member => {
            const assignedAssignments = assignments.filter(assignment => assignment.assigned.includes(member.name) && !assignment.resource).map(assignment => {
                let dueDate = dayjs(assignment.dueDate);
                const isPastDue = (dayjs() > dueDate);
                return {
                    _id: assignment._id,
                    assignmentTopicID: assignment.assignmentTopicID,
                    assignmentName: assignment.assignmentName,
                    uploads: assignment.assignmentGDriveFolders.filter(e => e.uploaded.some(uploaded => uploaded.name === member.name))
                        .map(e => e.uploaded.findLast(uploaded => uploaded.name === member.name).fileID),
                    dueDate: assignment.dueDate,
                    pastDue: isPastDue,
                    completed: assignment.completed.includes(member.name)
                }
            });
            
            const assignedSkills = skills.filter(skill => skill.assigned.includes(member.name)).map(skill => {
                return {
                    _id: skill._id,
                    skillSubgroupID: skill.skillSubgroupID,
                    skillName: skill.skillName,
                    completed: skill.completed.includes(member.name)
                }
            });

            const completedOutreachEvents = outreachEvents.filter(outreachEvent => outreachEvent.completed.includes(member.name)).map(outreachEvent => {
                return {
                    _id: outreachEvent._id,
                    outreachEventDateTime: outreachEvent.outreachEventDateTime,
                    outreachEventName: outreachEvent.outreachEventName,
                    outreachTimeHours: outreachEvent.outreachTimeHours
                }
            })

            return {
                profile: member,
                assignments: assignedAssignments,
                skills: assignedSkills,
                outreachEvents: completedOutreachEvents,
                academicProbation: compliance[0]?.names.includes(member.name)
            }
        }).sort(sortFunction.function);
    }, [members, sortFunction]);

    function complianceSort(a, b, order) {
        const pastDueA = a.assignments.some(e => e.pastDue);
        const pastDueB = b.assignments.some(e => e.pastDue);
        if (!a.academicProbation && !b.academicProbation) {
            if (pastDueA && !pastDueB) {
                return order === "desc" ? -1 : 1;
            } else if (!pastDueA && pastDueB) {
                return order === "desc" ? 1 : -1;
            }
        }

        if (a.academicProbation && !b.academicProbation) {
            return order === "desc" ? -1 : 1;
        } else if (!a.academicProbation && b.academicProbation) {
            return order === "desc" ? 1 : -1;
        } else {
            return 0;
        }
    }

    function totalHoursSort(a, b, order) {
        if (a.profile.TotalHours > b.profile.TotalHours) {
            return order === "desc" ? -1 : 1;
        } else if (a.profile.TotalHours < b.profile.TotalHours) {
            return order === "desc" ? 1 : -1;
        } else {
            return 0;
        }
    }

    if (user === 'NoUser') {
        return <FailedLoginPage />
    } else if (!user.admin) {
        return (
            <Center>
                <a style={{ marginTop: '100px' }} >
                    Not an admin
                </a>
            </Center>
        )
    } else {
        return (
            <>
                <Box userSelect="none">
                    <Tabs isFitted index={0} size="lg" colorScheme="green" background={"gray.800"} >
                        <Box background={"#3e3e3e"} overflow='auto'
                            position='sticky'
                            top='80px'
                            zIndex='998'
                            css={{
                                '&::-webkit-scrollbar': {
                                    height: '8px',
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    width: '6px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '24px',
                                },
                            }}>
                            <TabList width={"auto"} borderBottom="unset" minWidth={"fit-content"} zIndex={998} position={"sticky"}>
                                <Tab width={`${100 / 1}%`} mb={0} _hover={{ backgroundColor: '#3b473f' }}
                                    fontSize={{ base: 'sm', md: 'lg' }} backgroundColor={'#3b473f'}>
                                    Compliance Overview
                                </Tab>
                                <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: `calc(100% / 1)`,
                                    height: '3px',
                                    background: '#68D391',
                                    borderRadius: '5px',
                                    transition: 'all 350ms ease',
                                    zIndex: '998',
                                    left: `calc((calc(100% / 2) * 0))`
                                }}></div>
                            </TabList>
                        </Box>
                        <TabPanels marginTop='12px' background={"gray.800"} >
                            <Container maxW='3xl'>
                                <Card width="100%" mb={4} align='left' variant="filled" bg="transparent"
                                    _hover={{
                                        outline: "1px solid rgba(255,255,255, 0.16)"
                                    }}
                                >
                                    <CardHeader py={3}>
                                        <HStack>
                                            <Heading size='lg' _hover={{
                                                textDecoration: 'underline',
                                                color: '#3182ce'
                                            }}>Members</Heading>
                                            <Box flex="1" />
                                            <Menu>
                                                <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                                                    Sort By
                                                </MenuButton>
                                                <MenuList minWidth='180px'>
                                                    <MenuItem
                                                        bgColor={sortFunction.sortType === "compliance" ? 'gray.600' : 'unset'}
                                                        onClick={() => setSortFunction(oldValue => {
                                                            let newSortOrder = (oldValue.sortType === 'compliance' && oldValue.sortOrder) === 'desc' ? 'asc' : 'desc';
                                                            return {
                                                                sortType: 'compliance',
                                                                sortOrder: newSortOrder,
                                                                function: (a, b) => complianceSort(a, b, newSortOrder)
                                                            }
                                                        })}
                                                        icon={sortFunction.sortType === "compliance" ? sortFunction.sortOrder === "desc" ? <ChevronDownIcon boxSize={6} /> : <ChevronUpIcon boxSize={6} /> : null}>
                                                        Compliance
                                                    </MenuItem>
                                                    <MenuItem
                                                        bgColor={sortFunction.sortType === "hours" ? 'gray.600' : 'unset'}
                                                        onClick={() => setSortFunction(oldValue => {
                                                            let newSortOrder = (oldValue.sortType === 'hours' && oldValue.sortOrder) === 'desc' ? 'asc' : 'desc';
                                                            return {
                                                                sortType: 'hours',
                                                                sortOrder: newSortOrder,
                                                                function: (a, b) => totalHoursSort(a, b, newSortOrder)
                                                            }
                                                        })}
                                                        icon={sortFunction.sortType === "hours" ? sortFunction.sortOrder === "desc" ? <ChevronDownIcon boxSize={6} /> : <ChevronUpIcon boxSize={6} /> : null}>
                                                        Total Hours
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </HStack>
                                    </CardHeader>
                                    {assignmentCompliance.map((complianceItem, i) => {
                                        return <ComplianceCard key={i} topics={topics} subgroups={subgroups}
                                            complianceItem={complianceItem} />
                                    })}
                                </Card>
                            </Container>
                        </TabPanels>
                    </Tabs>
                </Box>
            </>
        );
    }
}
const MenuItemStyle = {
    _hover: { bg: "blackAlpha.200" },
    paddingTop: 3,
    paddingBottom: 3
}