import { HStack, Heading, Avatar, Icon, Box, Text, Flex, Checkbox } from "@chakra-ui/react";
import React from "react";

export default function NotCompletedSignOff(props) {
    let { name, iconImage, checkedItems, handleCheckItem, style } = props;

    return (
        <Flex borderTop={"1px solid rgba(255,255,255,0.16)"}
            style={style}
            _hover={{
                bg: "rgba(255,255,255,0.04)"
            }}>
            <Checkbox mx={3} w="fill-available" size="lg" isChecked={checkedItems.includes(name)} onChange={() => handleCheckItem(name)} >
                <Flex m={3} flex='1' gap='4' flexWrap="nowrap" alignItems={'center'} >
                    <Avatar size={{ base: 'sm', md: 'md' }} name={name} src={iconImage} />
                    <Box>
                        <Heading size={{ base: 'xs', md: 'sm' }}>{name}</Heading>
                        <Text color="darkgray" fontSize={{ base: 'sm', md: 'md' }}>Assigned</Text>
                    </Box>
                </Flex>
            </Checkbox>
        </Flex>
    )
}