import { HStack, Menu, MenuButton, IconButton, MenuList, MenuItem, Avatar, Icon, Box, Text, Divider, AccordionItem, AccordionButton, AccordionPanel, Stat, StatLabel, StatNumber, useDisclosure, VStack, Flex, Spacer, useBreakpointValue, Badge } from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiOutlineClipboardList, HiOutlineBookOpen } from "react-icons/hi";
import SignOffModal from "./SignOffModal";
import AddAssignmentModal from "./AddAssignmentModal";
import LinkCard from "./LinkCard";
import AddResourceModal from "./AddResourceModal";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import GDriveCard from "./GDriveCard";

export default function AssignmentCard(props) {
    const { isOpen: isOpenSignOff, onOpen: onOpenSignOff, onClose: onCloseSignOff } = useDisclosure();
    const { isOpen: isOpenEditAssignment, onOpen: onOpenEditAssignment, onClose: onCloseEditAssignment } = useDisclosure();
    const { isOpen: isOpenEditResource, onOpen: onOpenEditResource, onClose: onCloseEditResource } = useDisclosure();

    const { _id, assignmentName, assignmentTopicID, assignmentInstructions, assignmentLinks, dueDate, assigned, resource, completed, createdAt, assignmentGDriveFolders, dateLastEdited } = props.assignmentData;
    const { members, getTopics, getAssignments, editTopic, setEditTopic, topics, topicName, setAlertDialogFunction, setAlertDialogTitle, onOpenAlertDialog, user } = props;

    const editAssignment = useRef({ id: _id, assignmentName: assignmentName, assignmentInstructions: assignmentInstructions, assignmentLinks: assignmentLinks, dueDate: dueDate, assignmentTopicID: assignmentTopicID, assigned: assigned, assignmentGDriveFolders: assignmentGDriveFolders });
    const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });

    const [isCompleted, setIsCompleted] = useState(false);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: _id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const deleteOnClickHandler = () => {
        setAlertDialogFunction(() => () => {
            fetch(`/assignmentsTransactions/deleteAssignment`, {
                method: 'POST',
                // We convert the React state to JSON and send it as the POST body
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    _id: _id
                })
            }).then(response => {
                if (response.status === 200) {
                    getAssignments();
                }
                return response.text();
            }).then(function (data) {
                (data) && console.log(data);
            }).catch(err => {
                console.log(err);
            });
        });

        setAlertDialogTitle('Delete Assignment');
        onOpenAlertDialog();
    }

    const MapGDriveFolders = useMemo(() => assignmentGDriveFolders.map((folder, index) => {
        return (
            <Box key={index} mb={index !== assignmentGDriveFolders.length - 1 ? 2 : 0}>
                <GDriveCard url={folder.folderURL} name={folder.folderName} token={user.accessToken} assignmentID={_id} id={folder._id}
                    uploaded={folder.uploaded?.findLast(e => e.name === user.displayName)} getAssignments={getAssignments} />
            </Box>)
    }), [assignmentGDriveFolders]);

    const MapAssignmentLinks = useMemo(() => assignmentLinks.map((link, index) => {
        return (
            <Box key={link} mb={(index !== assignmentLinks.length - 1 || assignmentGDriveFolders.length > 0) ? 2 : 0}>
                <LinkCard url={link} />
            </Box>)
    }), [assignmentLinks, assignmentGDriveFolders]);

    useEffect(() => {
        editAssignment.current = {
            id: _id, assignmentName: assignmentName, assignmentInstructions: assignmentInstructions,
            assignmentLinks: assignmentLinks, dueDate: dueDate, assignmentTopicID: assignmentTopicID, assigned: assigned, assignmentGDriveFolders: assignmentGDriveFolders
        };
    }, [props.assignmentData]);

    useEffect(() => {
        setIsCompleted(completed.includes(user.displayName));
    }, [completed]);

    return (
        <>
            <SignOffModal isOpen={isOpenSignOff} onClose={onCloseSignOff} data={props.assignmentData} members={members} getAssignments={getAssignments} />
            {(editTopic && setEditTopic) && (
                <><AddAssignmentModal isOpen={isOpenEditAssignment} onClose={onCloseEditAssignment} topicName={topicName} token={user.accessToken}
                    topics={topics} members={members} getTopics={getTopics} getAssignments={getAssignments} editTopic={editTopic} setEditTopic={setEditTopic}
                    editAssignment={editAssignment.current} />
                    <AddResourceModal isOpen={isOpenEditResource} onClose={onCloseEditResource} topicName={topicName}
                        topics={topics} members={members} getTopics={getTopics} getAssignments={getAssignments} editTopic={editTopic} setEditTopic={setEditTopic}
                        editAssignment={editAssignment.current} />
                </>)
            }
            <AccordionItem ref={setNodeRef} style={style} {...attributes}
                borderBottom='none'
                _hover={{
                    bg: "rgba(255,255,255,0.04)"
                }}
            >
                {({ isExpanded }) => {
                    return (
                        <>
                            <Box backgroundColor={isExpanded ? "rgba(255,255,255,0.04)" : 'none'}>
                                <HStack spacing={4} pr="1.25rem" {...listeners}>
                                    <AccordionButton minW={0} pr={0}>
                                        <Avatar background={isCompleted ? "orange.300" : "orange.500"} w="2.2rem" h="2.2rem" mr={4} icon={<Icon as={resource ? HiOutlineBookOpen : HiOutlineClipboardList} w={6} h={6} color='white' />} />
                                        {isMobile ? <>
                                            <VStack spacing={0} alignItems={'flex-start'}>
                                                <Box textAlign={'left'}>
                                                    <Text fontSize={{ base: "sm", md: "md" }} minW={0} fontWeight='semibold' color="gray.200" isTruncated={true}>{assignmentName}</Text>
                                                    {dueDate ? <Text fontSize="xs" color="darkgray">Due {new Date(dueDate).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric"
                                                    })}</Text> :
                                                        !resource && <Text fontSize="xs" color="darkgray">No due date</Text>
                                                    }
                                                </Box>
                                            </VStack>
                                            <Box flex="1" />
                                            {!(editTopic && setEditTopic) &&
                                                <Badge variant='solid' colorScheme={isCompleted ? 'green' : 'gray'}>
                                                    {isCompleted && 'Completed'}
                                                </Badge>}
                                        </>
                                            : <>
                                                <Text fontSize={{ base: "sm", md: "md" }} minW={0} fontWeight='semibold' color="gray.200" isTruncated={true}>{assignmentName}</Text>
                                                <Box flex="1" />
                                                {isCompleted ?
                                                    !(editTopic && setEditTopic) &&
                                                    <Badge variant='solid' colorScheme={isCompleted ? 'green' : 'gray'}>
                                                        Completed
                                                    </Badge> :
                                                    dueDate ? <Text px={1} fontSize="xs" color="darkgray">Due {new Date(dueDate).toLocaleString("en-US", {
                                                        month: "short",
                                                        day: "numeric",
                                                        hour12: true,
                                                        hour: "numeric",
                                                        minute: "numeric"
                                                    })}
                                                    </Text > :
                                                        !resource && <Text px={1} fontSize="xs" color="darkgray">No due date</Text>
                                                }
                                            </>
                                        }
                                    </AccordionButton>
                                    {(editTopic && setEditTopic) &&
                                        <Menu>
                                            <MenuButton as={IconButton}
                                                aria-label='menu' bg="transparent" color="rgba(255,255,255,0.5)" size="lg" fontSize='20px' isRound icon={<BsThreeDotsVertical />}
                                                _hover={{
                                                    color: "white",
                                                    bg: "rgba(255,255,255,0.16)"
                                                }}
                                            />
                                            <MenuList minWidth={'8rem'} >
                                                {!resource && <>
                                                    <MenuItem pb={3} onClick={onOpenSignOff}>
                                                        Sign Off
                                                    </MenuItem>
                                                    <Divider />
                                                </>}
                                                <MenuItem py={3} onClick={resource ? onOpenEditResource : onOpenEditAssignment}>
                                                    Edit {resource? 'Resource' : 'Assignment'}
                                                </MenuItem>
                                                <MenuItem py={3} onClick={deleteOnClickHandler}>
                                                    Delete Assignment
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    }
                                </HStack>
                                {isExpanded && <Divider color={'rgba(255,255,255,0.16)'} />}
                                <AccordionPanel pb={(assignmentLinks.length > 0 || assignmentGDriveFolders.length > 0) ? 2 : 0}>
                                    <Flex mb={2}>
                                        <VStack spacing={0} alignItems="flex-start">
                                            <Text fontSize="xs" color="darkgray">{dateLastEdited ? ` Edited ${new Date(dateLastEdited).toLocaleString('en-US', { month: 'short', day: '2-digit' })}`
                                                : `Posted ${new Date(createdAt).toLocaleString('en-US', { month: 'short', day: '2-digit' })}`}
                                            </Text>
                                            <Text my={1} mr={1} fontSize="sm" whiteSpace={'pre-line'}>{assignmentInstructions}</Text>
                                        </VStack>
                                        {(!resource && editTopic && setEditTopic) ? <>
                                            <Spacer />
                                            <HStack onClick={onOpenSignOff} _hover={{ cursor: 'pointer' }} alignItems="flex-start">
                                                <Stat borderLeft="1px solid rgba(255,255,255,0.16)">
                                                    <Box mx={{ base: 2, md: 4 }}>
                                                        <StatNumber fontSize={{ base: 'md', md: '3xl' }}>{completed.length}</StatNumber>
                                                        <StatLabel>Completed</StatLabel>
                                                    </Box>
                                                </Stat>
                                                <Stat borderLeft="1px solid rgba(255,255,255,0.16)">
                                                    <Box mx={{ base: 2, md: 4 }}>
                                                        <StatNumber fontSize={{ base: 'md', md: '3xl' }}>{assigned.length}</StatNumber>
                                                        <StatLabel>Assigned</StatLabel>
                                                    </Box>
                                                </Stat>
                                            </HStack></> : <><Spacer />
                                            {!(editTopic && setEditTopic) && !resource &&
                                                <Text px={2} fontSize="xs" fontWeight="bold" color={'white'}>
                                                    Assigned
                                                </Text>}</>}
                                    </Flex>
                                    {MapAssignmentLinks}
                                    {MapGDriveFolders}
                                </AccordionPanel>
                            </Box>
                        </>)
                }}
            </AccordionItem >
        </>
    )
}
