import React, { useRef, useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, ModalFooter, Button } from "@chakra-ui/react";
import { customInputStyle } from "util/constants";

export default function AddLinkModal(props) {

    const { isOpen, onClose, setAssignmentLinks } = props;

    const linkField = useRef(null);
    const [linkValue, setLinkValue] = useState('');

    const handleLinkChange = (event) => setLinkValue(event.target.value);

    const resetModal = () => {
        onClose();
        linkField.current.value = '';
        setLinkValue('');
    }

    return (
        <>
            <Modal
                initialFocusRef={linkField}
                isOpen={isOpen}
                onClose={resetModal}
                size="xs"
                isCentered
            >
                <ModalOverlay />
                <ModalContent userSelect="none">
                    <ModalHeader pb={0}>Add link</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={2}>
                        <FormControl>
                            <Input ref={linkField} autoComplete='off' size="lg" placeholder='Link' onChange={handleLinkChange} sx={customInputStyle}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (linkValue.length !== 0 && new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig).test(linkValue)) {
                                            setAssignmentLinks(assignmentLinks => [...assignmentLinks, linkValue]);
                                            resetModal();
                                        }
                                    }
                                }} />
                        </FormControl>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' ml={3} isDisabled={linkValue.length === 0 ||
                            !new RegExp(/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig).test(linkValue)}
                            onClick={() => { setAssignmentLinks(assignmentLinks => [...assignmentLinks, linkValue]); resetModal(); }}>
                            Add
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}