import React, { useEffect, useState } from "react";
import { Image, Text, Center, Grid, GridItem, Heading, LinkOverlay, LinkBox } from "@chakra-ui/react";
import { FaGlobeAmericas } from "react-icons/fa";

export default function LinkCard(props) {

    let { url } = props;
    const [siteMetadata, setSiteMetadata] = useState(null);

    const fetchMetadata = async (data) => {
        const response = await fetch(`https://rlp-proxy.fly.dev/v2?url=${data}`);
        const json = await response.json();
        setSiteMetadata(json);
    };

    useEffect(() => {
        fetchMetadata(url);
    }, []);

    return (
        <LinkBox borderWidth='1px' rounded='md' width='100%'>
            <LinkOverlay href={!url.includes('://') ? "https://".concat(url) : url} isExternal>
                <Grid
                    mr={2}
                    h='60px'
                    templateRows='repeat(2, 1fr)'
                    templateColumns='repeat(8, 1fr)'
                    gap={2}
                    rowGap={0}
                >
                    <GridItem rowSpan={2} colSpan={1} alignContent="center"
                        width='80px' borderRight="1px solid rgba(255,255,255,0.16)">
                        <Center>
                            <Image maxW={'4.5rem'} maxH='58px' src={isImage(url) ? url : siteMetadata?.metadata?.image} fallback={<FaGlobeAmericas fontSize={32} />} alt="Website Thumbnail" />
                        </Center>
                    </GridItem>
                    <GridItem colSpan={7} alignContent="center">
                        <Heading size='xs' mt={2} isTruncated={true}>
                            {siteMetadata?.metadata?.title ? siteMetadata?.metadata?.title : isImage(url) ? 'Image' : 'Link'}
                        </Heading>
                    </GridItem>
                    <GridItem colSpan={7}>
                        <Text fontSize="sm" fontWeight={'light'} color={'darkgray'} isTruncated={true}>
                            {!url.includes('://') ? "https://".concat(url) : url}
                        </Text>
                    </GridItem>
                </Grid>
            </LinkOverlay>
        </LinkBox>
    )
}

function isImage(url) {
    return (url.match(/\.(jpeg|jpg|gif|png|webp)$/) != null);
}
