import React, { useContext, useState } from 'react';
import { Box, Button, Center, Tab, TabList, TabPanels, Tabs, MenuButton, Menu, MenuItem, MenuList, Container, useDisclosure, VStack, Card, CardHeader, HStack, Heading, Accordion } from '@chakra-ui/react';
import { AuthContext } from 'context/auth';
import { DataContext } from 'context/data';
import AlertDialogComponent from 'components/AlertDialogComponent';
import { AddIcon } from '@chakra-ui/icons';
import { IoMdCalendar } from "react-icons/io";
import AddOutreachModal from 'components/AddOutreachModal';
import OutreachEventCard from 'components/OutreachEventCard';
import FailedLoginPage from './FailedLoginPage';

export default function OutreachPage() {
    const { user } = useContext(AuthContext);
    const { members, outreachEvents, getOutreachEvents } = useContext(DataContext);

    const [alertDialogTitle, setAlertDialogTitle] = useState();
    const [alertDialogFunction, setAlertDialogFunction] = useState(() => () => { });

    const { isOpen: isOpenAlertDialog, onOpen: onOpenAlertDialog, onClose: onCloseAlertDialog } = useDisclosure();
    const { isOpen: isOpenAddOutreach, onOpen: onOpenAddOutreach, onClose: onCloseAddOutreach } = useDisclosure();

    if (user === 'NoUser') {
        return <FailedLoginPage/>
    } else if (!user.admin) {
        return (
            <Center>
                <a style={{ marginTop: '100px' }} >
                    Not an admin
                </a>
            </Center>
        )
    } else {
        return (
            <>
                <AlertDialogComponent isOpen={isOpenAlertDialog} onClose={onCloseAlertDialog} title={alertDialogTitle} alertDialogFunction={alertDialogFunction} />
                <AddOutreachModal isOpen={isOpenAddOutreach} onClose={onCloseAddOutreach} getOutreachEvents={getOutreachEvents}
                    members={members} />
                <Box userSelect="none">
                    <Tabs isFitted index={0} size="lg" colorScheme="green" background={"gray.800"} >
                        <Box background={"#3e3e3e"} overflow='auto'
                            position='sticky'
                            top='80px'
                            zIndex='998'
                            css={{
                                '&::-webkit-scrollbar': {
                                    height: '8px',
                                    width: '4px',
                                },
                                '&::-webkit-scrollbar-track': {
                                    width: '6px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: '#888',
                                    borderRadius: '24px',
                                },
                            }}>
                            <TabList width={"auto"} borderBottom="unset" minWidth={"fit-content"} zIndex={998} position={"sticky"}>
                                <Tab width={`${100 / 1}%`} mb={0} _hover={{ backgroundColor: '#3b473f' }} fontSize={{ base: 'sm', md: 'lg' }} backgroundColor={'#3b473f'}>Outreach Hours</Tab>
                                <div style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: `calc(100% / 1)`,
                                    height: '3px',
                                    background: '#68D391',
                                    borderRadius: '5px',
                                    transition: 'all 350ms ease',
                                    zIndex: '998',
                                    left: `calc((calc(100% / 2) * 0))`
                                }}></div>
                            </TabList>
                        </Box>
                        <TabPanels marginTop='12px' background={"gray.800"} >
                            <Container maxW='3xl'>
                                <VStack spacing={3} alignItems={'flex-start'}>
                                    <Menu>
                                        <MenuButton as={Button} h={12} backgroundColor="blue.500" rounded="3xl" size='md'
                                            _hover={{ bg: "blue.500" }} _active={{ bg: "blue.500" }} leftIcon={<AddIcon />}>
                                            Create
                                        </MenuButton>
                                        <MenuList>
                                            <MenuItem icon={<IoMdCalendar fontSize={22} />} sx={MenuItemStyle} onClick={onOpenAddOutreach}>Outreach Event</MenuItem>
                                        </MenuList>
                                    </Menu>
                                    <Card width="100%" mb={4} align='left' variant="filled" bg="transparent"
                                        _hover={{
                                            outline: "1px solid rgba(255,255,255, 0.16)"
                                        }}
                                    >
                                        <CardHeader py={3}>
                                            <HStack>
                                                <Heading size='lg' _hover={{
                                                    textDecoration: 'underline',
                                                    color: '#3182ce'
                                                }}> Outreach Events</Heading>
                                                <Box flex="1" />
                                            </HStack>
                                        </CardHeader>
                                        <Accordion allowToggle w={'100%'}>
                                            {outreachEvents.map(outreachEventData => {
                                                return <OutreachEventCard key={outreachEventData._id} user={user} outreachEventData={outreachEventData}
                                                    getOutreachEvents={getOutreachEvents} members={members}
                                                    setAlertDialogFunction={setAlertDialogFunction} setAlertDialogTitle={setAlertDialogTitle} onOpenAlertDialog={onOpenAlertDialog}/>
                                            })}
                                        </Accordion>
                                    </Card>
                                </VStack>
                            </Container>
                        </TabPanels>
                    </Tabs>
                </Box>
            </>
        );
    }
}
const MenuItemStyle = {
    _hover: { bg: "blackAlpha.200" },
    paddingTop: 3,
    paddingBottom: 3
}